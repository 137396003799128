import React, { useState, useRef, useEffect } from 'react';
import api from "../service/api";
import { Messages } from 'primereact/messages';
import Mensagens from '../globais/Mensagens';
import BTInativar from '../components/BTInativar';
import BTNovo from '../components/BTNovo';
import BTGravar from '../components/BTGravar';
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { AutoComplete } from 'primereact/autocomplete';
import { Checkbox } from 'primereact/checkbox';
import ConsultaFixaGenerica from '../components/consultas/ConsultaFixaGenerica';

const ConfiguracaoExportaFolha = () => {

    let emptyModel = {
        id: null,
        empresa: null,
        aplicacao: null,
        dataS: '',
    };

    const toast = useRef(null);
    const [model, setModel] = useState(emptyModel);
    const [lista, setLista] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [autoFilteredValueEmpresa, setAutoFilteredValueEmpresa] = useState([]);
    const [autoValueEmpresa, setAutoValueEmpresa] = useState(null);
    const [autoFilteredValueAplicacao, setAutoFilteredValueAplicacao] = useState([]);
    const [autoValueAplicacao, setAutoValueAplicacao] = useState(null);
    const [todasEmpresas, setTodasEmpresas] = useState(false);

    const colunas = [
        { field: 'empresa.fantasia', header: 'Empresa', headerStyle: { minWidth: '15rem' } },
        { field: 'aplicacao.descricao', header: 'Aplicação', headerStyle: { maxWidth: '5rem' } },
        { field: 'dataS', header: 'Data Histórica', headerStyle: { maxWidth: '5rem' } },
    ];

    const message = useRef();

    useEffect(() => {

        const buscaEmpresas = () => {
            api
                .get("/empresas")
                .then(response => {
                    model.empresa = response.data[0];
                    setAutoValueEmpresa(response.data)
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }

        const buscaExportaFolha = () => {
            api
                .get("/regras/configuracoes/exportafolha")
                .then(response => {
                    setLista(response.data)
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }

        const buscaAplicacao = () => {
            api
                .get("/aplicacoes")
                .then(response => {
                    setAutoValueAplicacao(response.data)
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }

        buscaEmpresas();
        buscaExportaFolha();
        buscaAplicacao();

    }, []);

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <BTGravar setSubmitted={setSubmitted} cadastrar={cadastrar} />
                    <BTNovo setSubmitted={setSubmitted} setModel={setModel} emptyModel={emptyModel} />
                    <BTInativar inativar={inativar} />
                    <Messages ref={message} />
                </div>
            </React.Fragment>
        )
    }

    const addMessage = (sv, cn) => {
        message.current.show({ severity: sv, content: cn });
    };

    const procurarEmpresas = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setAutoFilteredValueEmpresa([...autoValueEmpresa]);
            }
            else {
                setAutoFilteredValueEmpresa(autoValueEmpresa.filter((em) => {
                    return em.fantasia.toLowerCase().startsWith(event.query.toLowerCase());
                }));
            }
        }, 250);
    };

    const procurarAplicacoes = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setAutoFilteredValueAplicacao([...autoValueAplicacao]);
            }
            else {
                setAutoFilteredValueAplicacao(autoValueAplicacao.filter((em) => {
                    return em.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                }));
            }
        }, 250);
    };


    const onInputChange = (e, descricao) => {
        const val = (e.target && e.target.value) || '';
        let _model = { ...model };
        _model[`${descricao}`] = val;
        setModel(_model);
    }

    const onCheckboxChange = (e, descricao) => {
        const val = (e.value) || '';
        let _model = { ...model };
        let valor = val === 1 ? 0 : 1;
        _model[`${descricao}`] = valor;
        setModel(_model);
    };

    const selecao = (e) => {
        setModel(e.value);
    }

    const cadastrar = () => {
        if ((model.empresa || todasEmpresas) && model.aplicacao) {
            api
                .post("/regras/configuracoes/exportafolha", {
                    id: model.id,
                    empresa: model.empresa,
                    aplicacao: model.aplicacao,
                    todasEmpresas: todasEmpresas,
                })
                .then(response => {
                    setLista(response.data);
                    addMessage("success", Mensagens.CADASTRO_INCLUIR);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }
    }

    const inativar = () => {
        if (model.id) {
            api
                .delete("/regras/configuracoes/exportafolha/" + model.id)
                .then(response => {
                    addMessage("success", Mensagens.CADASTRO_INATIVAR);
                    setLista(response.data);
                    setSubmitted(false);
                    setModel(emptyModel);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        } else {
            addMessage("warn", Mensagens.SELECIONE);
        }

    }

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="empresa" className="col-12 md:col-1 md:mb-2">Empresa*</label>
                        <div className="col-12 md:col-9 mb-2">
                            <AutoComplete placeholder="Procure" id="empresa" dropdown value={model.empresa} onChange={(e) => onInputChange(e, 'empresa')} suggestions={autoFilteredValueEmpresa} completeMethod={procurarEmpresas} field="fantasia" required className={classNames({ 'p-invalid': submitted && !model.empresa && !todasEmpresas })} />
                            {submitted && !model.empresa && !todasEmpresas && <small className="p-invalid">Empresa Obrigatória</small>}
                        </div>
                        <div className="field-checkbox p-fluid mt-2 col-2">
                            <Checkbox inputId="checkOption1" name="todasempresas" value="Todas Empresas" checked={todasEmpresas} onChange={e => setTodasEmpresas(e.checked)} />
                            <label htmlFor="checkOption1">Todas Empresas</label>
                        </div>
                    </div>

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="aplicacao" className="col-12 md:col-1 md:mb-0">Aplicação*</label>
                        <div className="col-12 md:col-9">
                            <AutoComplete placeholder="Procure" id="aplicacao" dropdown value={model.aplicacao} onChange={(e) => onInputChange(e, 'aplicacao')} suggestions={autoFilteredValueAplicacao} completeMethod={procurarAplicacoes} field="descricao" required className={classNames({ 'p-invalid': submitted && !model.aplicacao })} />
                            {submitted && !model.aplicacao && <small className="p-invalid">Aplicação Obrigatória</small>}
                        </div>
                    </div>

                    <div className="col-12">
                        <Toast ref={toast} />
                        <Toolbar className="mb-2 " left={leftToolbarTemplate}></Toolbar>
                    </div>

                    <ConsultaFixaGenerica
                        lista={lista}
                        model={model}
                        selecao={selecao}
                        camposFiltroGlobal={['empresa.fantasia', 'aplicacao.descricao']}
                        colunas={colunas}
                    />

                </div >
            </div >
        </div >
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ConfiguracaoExportaFolha, comparisonFn);
