import React from 'react';
import { useHistory, useLocation, withRouter } from 'react-router-dom';


const AppBreadcrumb = () => {

    const location = useLocation();
    const history = useHistory();

    const paths = location.pathname.split('/');

    const onLogout = () => {
        localStorage.removeItem("usuario");
        localStorage.removeItem("cliente");
        history.push('/login');
    }

    return (
        <div className="layout-breadcrumb">
            <ul>
                <li><button type="button" className="p-link" onClick={() => history.push('/')}><i className="pi pi-home"></i></button></li>
                {
                    location.pathname === '/' ? <li>/</li> : paths.map((path, index) => <li key={index}>{path === '' ? '/' : path}</li>)
                }
            </ul>

            <div className="layout-breadcrumb-options">
                <button type="button" className="p-link" title="Whatsapp" onClick={() => history.push('/error')}>
                    <i className="pi pi-whatsapp"></i>
                </button>
                <button type="button" className="p-link" title="Instagram" onClick={() => history.push('/error')}>
                    <i className="pi pi-instagram"></i>
                </button>
                <button type="button" className="p-link" title="Linkedin" onClick={() => history.push('/error')}>
                    <i className="pi pi-linkedin"></i>
                </button>
                <button type="button" className="p-link" title="Twitter" onClick={() => history.push('/error')}>
                    <i className="pi pi-twitter"></i>
                </button>
                <button type="button" className="p-link" title="Facebook" onClick={() => history.push('/error')}>
                    <i className="pi pi-facebook"></i>
                </button>
                <button type="button" className="p-link" title="Sair" onClick={onLogout}>
                    <i className="pi pi-power-off"></i>
                </button>
            </div>
        </div>
    );

}

export default withRouter(AppBreadcrumb);
