import React, { useState, useRef } from 'react';
import { Messages } from 'primereact/messages';
import BTGravar from '../components/BTGravar';
import { Fieldset } from 'primereact/fieldset';
import { TabMenu } from 'primereact/tabmenu';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';

const PerfilContaUsuarioAssinatura = () => {

    let planoModel = {
        id: null,
        nomePlano: 'Básico - R$ XX,XX',
        dataRenovacao: 'XX/XX/XXXX',
        dataAssinatura: 'XX/XX/XXXX'
    };

    const toast = useRef(null);
    const [modelPlano, setModelPlano] = useState(planoModel);
    const [lista, setLista] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const message = useRef();
    const history = useHistory();

    const [activeIndex, setActiveIndex] = useState(1);

    const items = [
        { label: 'Configurações', icon: 'pi pi-fw pi-cog' },
        { label: 'Assinatura', icon: 'pi pi-fw pi-money-bill' }
    ];

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <BTGravar setSubmitted={setSubmitted} cadastrar={cadastrar} />
                    <Messages ref={message} />
                </div>
            </React.Fragment>
        )
    }

    // const onInputChange = (e, descricao) => {
    //     const val = (e.target && e.target.value) || '';
    //     let _model = { ...model };
    //     _model[`${descricao}`] = val;
    //     setModel(_model);
    // }


    const cadastrar = () => {
        // if (model.descricao.trim()) {
        //     if (model.id) {
        //         api
        //             .put("/cargos/" + model.id, {
        //                 id: model.id,
        //                 descricao: model.descricao
        //             })
        //             .then(response => {
        //                 setModel(response.data);
        //                 addMessage("success", Mensagens.CADASTRO_ALTERAR);
        //             })
        //             .catch(() => {
        //                 addMessage("error", Mensagens.ERRO);
        //             })
        //     }
        //     else {
        //         api
        //             .post("/cargos", {
        //                 id: model.id,
        //                 descricao: model.descricao
        //             })
        //             .then(response => {
        //                 setModel(response.data);
        //                 addMessage("success", Mensagens.CADASTRO_INCLUIR);
        //             })
        //             .catch(() => {
        //                 addMessage("error", Mensagens.ERRO);
        //             })s
        //     }
        // }
    }

    const addMessage = (sv, cn) => {
        message.current.show({ severity: sv, content: cn });
    };

    const onTabMudanca = (event) => {
        console.log(event)
        if (event.index === 0) {
            history.push("/perfil/conta/configuracoes");
        } else if (event.index === 1) {
            history.push("/perfil/conta/assinatura");
        }
    }

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">

                    <h5>Minha Conta</h5>
                    <TabMenu className='mb-2' model={items} activeIndex={activeIndex} onTabChange={onTabMudanca} />

                    <Fieldset legend="Situação Atual" className='mb-2'>
                        <div className="grid">
                            <div className="col-3 md:col-6">
                                <div style={{ marginBottom: '0' }}>
                                    <p style={{ margin: '0' }}>Plano Atual</p>
                                </div>
                                <div>
                                    <h5 style={{ margin: '0' }}><strong>{modelPlano?.nomePlano}</strong></h5>
                                </div>
                            </div>
                            <div className="col-9 md:col-6 mt-0 md:mt-0 text-right">
                                <Button icon="pi pi-pencil" className="p-button-text" label='Alterar' />
                            </div>
                            <div className="col-3 md:col-6">
                                <div style={{ marginBottom: '0' }}>
                                    <p style={{ margin: '0' }}>Sua Assinatura</p>
                                </div>
                                <div>
                                    <h5 style={{ margin: '0' }}><strong>{"Início: " + modelPlano?.dataAssinatura + " | Renovação: " + modelPlano?.dataRenovacao}</strong></h5>
                                </div>
                            </div>
                            <div className="col-9 md:col-6 mt-0 md:mt-0 text-right">
                                <Button icon="pi pi-times-circle" className="p-button-text" label='Cancelar Assinatura' />
                            </div>
                        </div>
                    </Fieldset>

                    {/* <Fieldset legend="Situação atual" className='mb-2'>
                        <div className="grid">
                            <div className="col-8 md:col-8">
                                <label htmlFor="plano" className="mb-0 mt-0 md:col-1 md:mb-0">Plano atual:</label>
                                <label htmlFor="plano" style={{ fontSize: '18px', marginLeft: '5px' }} className="mb-2 md:col-1 md:mb-0"><strong>{modelPlano?.nomePlano}</strong></label>
                            </div>
                            <div className="col-4 md:col-4 mt-0 md:mt-0 text-right">
                                <Button icon="pi pi-pencil" className="p-button-text" label='Alterar' />
                            </div>
                            <div className="col-8 md:col-8">
                                <label htmlFor="datacadastro" className="mb-0 mt-0 md:col-1 md:mb-0">Sua assinatura:</label>
                                <label htmlFor="datacadastro" style={{ fontSize: '18px', marginLeft: '5px' }} className="mb-2 md:col-1 md:mb-0"><strong>{"Início: " + modelPlano?.dataAssinatura + " | Renovação: " + modelPlano?.dataRenovacao}</strong></label>
                            </div>
                            <div className="col-4 md:col-4 mt-0 md:mt-0 text-right">
                                <Button icon="pi pi-times-circle" className="p-button-text" label='Cancelar' />
                            </div>
                        </div>
                    </Fieldset> */}

                </div >
            </div >
        </div >
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(PerfilContaUsuarioAssinatura, comparisonFn);
