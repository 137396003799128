import React from 'react';

const AppFooter = () => {

    return (
        <div className="layout-footer">
            <span className="footer-text-left">HEUP - Desenvolvido Por HE Sistemas Empresariais (66) 3424-2666</span>
            {/* <span className="footer-text-right">Teste</span> */}
            {/* <div> */}
                {/* <img className="footer-text-right" alt="logo" src="assets/layout/images/logo-white-he.png" /> */}
                {/* <img className="footer-text-right" alt="logo" src="assets/layout/images/topdata-revenda.jpg" /> */}
            {/* </div> */}
        </div >
    )

}

export default AppFooter;