import { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { useHistory } from 'react-router-dom';
import { classNames } from 'primereact/utils';
import { Messages } from 'primereact/messages';
import Mensagens from "../globais/Mensagens";
import api from "../service/api";
import { Dialog } from "primereact/dialog";
import { Panel } from "primereact/panel";
import { InputMask } from 'primereact/inputmask';
import { Calendar } from 'primereact/calendar';
import { addLocale, locale } from 'primereact/api';

export const MarcarPontoEfetuar = () => {

	let emptyModel = {
		senha: '',
		repitaSenha: '',
	};

	addLocale('br', {
		firstDayOfWeek: 1,
		dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
		dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
		dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
		monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
		monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
		today: 'Hoje',
		clear: 'Limpar'
	});

	locale("br");

	let hoje = new Date();

	const history = useHistory();
	const [submitted, setSubmitted] = useState(false);
	const [model, setModel] = useState(emptyModel);
	const message = useRef();
	const messageDialog = useRef();
	const [imageUrl, setImageUrl] = useState("");
	const [isInsideArea, setIsInsideArea] = useState(false);
	const [colaborador, setColaborador] = useState(null);
	const [localizacaoDentro, setLocalizacaoDentro] = useState(null);
	// const [dataCorrente, setDataCorrente] = useState(new Date());
	// const [dataHora, setDataHora] = useState("");
	const [dataHora, setDataHora] = useState(new Date().toLocaleString());
	const [uuid, setUuid] = useState(null);
	const [presencaSenha, setPresencaSenha] = useState(true);
	const [batidaViaLinkDados, setBatidaViaLinkDados] = useState(null);
	const [displayConfirmation, setDisplayConfirmation] = useState(false);
	const [displaySenha, setDisplaySenha] = useState(false);
	const [displayPerdeuSenha, setDisplayPerdeuSenha] = useState(false);
	const [displayHistorico, setDisplayHistorico] = useState(false);
	const [latitudeAtual, setLatitudeAtual] = useState('');
	const [longitudeAtual, setLongitudeAtual] = useState('');
	const [linkComprovante, setLinkComprovante] = useState(null);
	const [jornadaAtual, setJornadaAtual] = useState(null);
	const [linkHistorico, setLinkHistorico] = useState(null);
	// const [dataInicialHistorico, setDataInicialHistorico] = useState(null);
	// const [dataFinalHistorico, setDataFinalHistorico] = useState(null);
	const [dataInicial, setDataInicial] = useState(hoje);
	const [dataFinal, setDataFinal] = useState(hoje);


	useEffect(() => {
		const timer = setInterval(() => {
			setDataHora(new Date().toLocaleString());
		}, 1000);

		return () => {
			clearInterval(timer);
		};
	}, []);

	useEffect(() => {

		// Obtenha a parte da URL após o caractere '#'
		const hash = window.location.hash;

		// Remova o caractere '#/' do início da string
		const link = hash.substring(2);

		let uuidLink = link.split("/")[1];

		setUuid(uuidLink);

		let uuidDividido = uuidLink.split("-");

		let idCliente = uuidDividido[uuidDividido.length - 1];

		// cliente temporario
		const cliente = { "id": idCliente, "nomeCliente": "BatidaViaLink" };
		localStorage.setItem("cliente", JSON.stringify(cliente));

		const usuario = { "token": "BatidaViaLink", "username": "Indefinido" };
		localStorage.setItem("usuario", JSON.stringify(usuario));

		api.get("/clientes/" + idCliente)
			.then(response => {
				localStorage.setItem("clien te", JSON.stringify(response.data));
			})
			.catch(() => {
				addMessage("error", Mensagens.ERRO);
			})

		api.get("/batidaViaLink/permissoes/" + uuidLink)
			.then(response => {
				if (!response.data) {
					goNegado();
				}

				setBatidaViaLinkDados(response.data);
				setColaborador(response.data.colaborador);


				// const formattedDate = dataCorrente.toLocaleString();
				// setDataHora(formattedDate);

				setPresencaSenha(response.data.presencaoSenha);

				if (response.data.presencaoSenha === false) {
					setDisplaySenha(true);
				}

				// console.log("response presenca senha")
				// console.log(response.data.presencaoSenha)
				if (navigator.geolocation) {

					navigator.geolocation.getCurrentPosition(function (position) {
						const lat = position.coords.latitude;
						const lon = position.coords.longitude;

						setLatitudeAtual(lat);
						setLongitudeAtual(lon);

						// Defina o ponto central e o raio da área
						// const areaLat = -16.43558;
						// const areaLon = -54.66199;
						// const radius = 1000; // em metros

						//minha casa
						// const areaLat = -16.43558;
						// const areaLon = -54.66199;
						// const radius = 1000; // em metros
						setLocalizacaoDentro({ id: -1, nome: "Lat: " + lat + " Lon: " + lon });

						if (response.data.localizacoes.length > 0) {
							//para cada localizacao, verifica se está no raio (dentro ou fora)
							response.data.localizacoes.forEach(item => {
								// Calcule a distância entre a localização do usuário e o ponto central da área
								let distance = getDistanceFromLatLonInMeters(lat, lon, item.latitude, item.longitude);

								// Verifique se a localização do usuário está dentro da área

								if (distance <= item.raio) {
									setIsInsideArea(true);
									setLocalizacaoDentro(item);
								}

							});
						} else {
							setIsInsideArea(true);
							// setLocalizacaoDentro({ id: -1, nome: "Lat: " + lat + " Lon: " + lon });
						}


						const zoom = 19;
						// const url = `https://api.openstreetmap.org/api/0.6/map?bbox=${lon - 0.01},${lat - 0.01},${lon + 0.01},${lat + 0.01}`;
						// fetch(url)
						// 	.then((response) => response.text())
						// 	.then((data) => {
						// 		const parser = new DOMParser();
						// 		const xml = parser.parseFromString(data, "text/xml");
						// 		const node = xml.querySelector("node");
						// 		const url = `https://www.openstreetmap.org/#map=${zoom}/${lat}/${lon}/&layers=N`;
						// 		setImageUrl(url);
						// 	});
						const url = `https://www.openstreetmap.org/?mlat=${lat}&mlon=${lon}&zoom=${zoom}`;
						// const url = `https://www.openstreetmap.org/#map=${zoom}/${lat}/${lon}/&layers=N`;
						setImageUrl(url);
					});
				} else {
					alert("Localização não disponível no navegador!");
				}
			})
			.catch(() => {
				addMessage("error", Mensagens.ERRO);
			})
	}, []);


	// Função para calcular a distância entre dois pontos geográficos em metros
	const getDistanceFromLatLonInMeters = (lat1, lon1, lat2, lon2) => {
		const R = 6371; // raio da Terra em km
		const dLat = deg2rad(lat2 - lat1);
		const dLon = deg2rad(lon2 - lon1);
		const a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		const d = R * c * 1000; // converte para metros
		return d;
	};

	// Função para converter graus em radianos
	const deg2rad = (deg) => {
		return deg * (Math.PI / 180);
	};

	const goError = () => {
		history.push('/error');
	}

	const onInputChange = (e, descricao) => {
		const val = (e.target && e.target.value) || '';
		let _model = { ...model };
		_model[`${descricao}`] = val;
		setModel(_model);
	}

	const confirmationDialogFooter = (
		<>
			<Button type="button" label="Não" icon="pi pi-times" onClick={() => setDisplayConfirmation(false)} className="p-button-text" autoFocus />
			<Button type="button" label="Sim" icon="pi pi-check" onClick={() => cadastrarSenha()} className="p-button-text" />
		</>
	)

	const perdeuSenhaDialogFooter = (
		<>
			<Button type="button" label="Entendi" icon="pi pi-thumbs-up" onClick={() => setDisplayPerdeuSenha(false)} className="p-button-text" autoFocus />
		</>
	)


	const addMessage = (sv, cn) => {
		message.current.show({ severity: sv, content: cn });
	};

	const addMessageDialog = (sv, cn) => {
		messageDialog.current.show({ severity: sv, content: cn });
	};


	const cadastrarSenha = () => {
		if (batidaViaLinkDados.id && model.senha.length > 5) {
			if (model.senha === model.repitaSenha) {
				api
					.put("/batidaViaLink/permissoes/" + batidaViaLinkDados.id, {
						id: batidaViaLinkDados.id,
						senha: model.senha
					})
					.then(() => {
						// setModel(response.data);
						addMessage("success", Mensagens.SENHA_CRIADA);
						setPresencaSenha(true);
						setDisplayConfirmation(false);
						setDisplaySenha(false);
					})
					.catch(() => {
						addMessageDialog("error", Mensagens.ERRO);
					})
			} else {
				setDisplayConfirmation(false);
				addMessageDialog("error", Mensagens.SENHAS_DIFERENTES);
			}
		} else {
			setDisplayConfirmation(false);
			addMessageDialog("error", Mensagens.VERIFIQUE_SENHA);
		}
	}

	const baterPonto = () => {
		if (isInsideArea) { //se estiver dentro da area, pode bater ponto
			api
				.post("/batidaEnviada", {
					colaborador: colaborador,
					motivo: "batidaViaLink",
					dataS: dataHora,
					latitude: latitudeAtual,
					longitude: longitudeAtual,
					localizacao: localizacaoDentro,
					senha: model.senha,
					dadosPermissao: batidaViaLinkDados
				}, { responseType: 'arraybuffer' })
				.then((response) => {
					const file = new Blob(
						[response.data],
						{ type: 'application/pdf' });

					const fileURL = URL.createObjectURL(file);

					setLinkComprovante(fileURL);

					window.open(fileURL);
					addMessage("success", Mensagens.OPERACAO_SUCESSO);

				})
				.catch(error => {
					console.log(error.response);
					if (error.response.status === 400) {
						addMessage("error", Mensagens.SENHA_ERRADA);
					} else {
						addMessage("error", Mensagens.ERRO);
					}
				})
		} else {
			addMessage("error", Mensagens.FORA_AREA_PONTO)
		}
	}

	const historicoBatidas = () => {
		setSubmitted(true);
		if (dataInicial && dataFinal) {
			const diaInicial = dataInicial.getUTCDate();
			const mesInicial = dataInicial.getUTCMonth() + 1; // Os meses em JavaScript são base 0 (janeiro = 0, fevereiro = 1, ...)
			const anoInicial = dataInicial.getUTCFullYear();

			const dataInicialFormatada = `${diaInicial < 10 ? '0' : ''}${diaInicial}/${mesInicial < 10 ? '0' : ''}${mesInicial}/${anoInicial}`;

			const diaFinal = dataFinal.getUTCDate();
			const mesFinal = dataFinal.getUTCMonth() + 1; // Os meses em JavaScript são base 0 (janeiro = 0, fevereiro = 1, ...)
			const anoFinal = dataFinal.getUTCFullYear();

			const dataFinalFormatada = `${diaFinal < 10 ? '0' : ''}${diaFinal}/${mesFinal < 10 ? '0' : ''}${mesFinal}/${anoFinal}`;

			api
				.post("/batidaEnviada/colaborador/" + colaborador.id + "/historico", {
					colaborador: colaborador,
					dataInicialS: dataInicialFormatada,
					dataFinalS: dataFinalFormatada,
				}, { responseType: 'arraybuffer' })
				.then((response) => {
					const file = new Blob(
						[response.data],
						{ type: 'application/pdf' });

					const fileURL = URL.createObjectURL(file);

					setLinkHistorico(fileURL);

					window.open(fileURL);
					addMessage("success", Mensagens.OPERACAO_SUCESSO);

				})
				.catch(error => {
					if (error.response.status === 400) {
						addMessage("error", Mensagens.SENHA_ERRADA);
					} else {
						addMessage("error", Mensagens.ERRO);
					}
				})
			setSubmitted(false);
		}
		// else {
		// 	addMessage("error", "")
		// }
	}

	const goNegado = () => {
		history.push('/access');
	}

	const goHistoricoBatidas = () => {
		setDisplayHistorico(true);
	}

	return (
		<div className="login-body">
			<div className="body-container">
				<div className="grid">
					<div className="col-12 lg:col-6 left-side">
						{/* <img src="assets/layout/images/logo-white-he.png" alt="apollo-layout" className="logo" /> */}
						{/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', textAlign: 'center' }}> */}
						<div>
							{isInsideArea ? (
								<div>
									<span style={{ fontSize: '80px', display: 'block' }}>
										DENTRO
									</span>
									<span style={{ fontSize: '16px', display: 'block' }}>
										da área definida
									</span>
								</div>
							) : (
								<div>
									<span style={{ fontSize: '80px', display: 'block' }}>
										FORA
									</span>
									<span style={{ fontSize: '16px', display: 'block' }}>
										da área definida
									</span>
								</div>
							)}
							<br />
							<a href={imageUrl} style={{ fontSize: '16px' }} target="_blank" rel="noopener noreferrer">
								Visualizar minha posição no mapa
							</a>
						</div>
					</div>
					<div className="col-12 lg:col-6 right-side">
						<div className="login-wrapper">
							<div className="login-container">
								<span className="title">Autorização da batida</span>
								<div className="grid p-fluid">

									<div className="col-12" style={{ textAlign: 'left' }}>
										<label htmlFor="data">{"Data: " + dataHora}</label>
									</div>

									<div className="col-12" style={{ textAlign: 'left' }}>
										<label htmlFor="colaborador">Colaborador: <strong>{colaborador?.nome}</strong></label>
									</div>

									{/* <div className="col-12" style={{ textAlign: 'left' }}>
										<label htmlFor="jornada">{"Jornada atual: " + jornadaAtual?.nome}</label>
									</div> */}

									<div className="col-12" style={{ textAlign: 'left' }}>
										<label htmlFor="localizacao">{"Local Atual: " + localizacaoDentro?.nome}</label>
									</div>

									<div className="col-12">
										<Password placeholder="Senha" toggleMask feedback={false} onChange={(e) => onInputChange(e, 'senha')} required className={classNames({ 'p-invalid': submitted && !model.senha })} />
										{submitted && !model.senha && <small className="p-invalid">Senha obrigatória</small>}
									</div>

									<div className="col-6" style={{ display: presencaSenha === false ? ' ' : 'none' }}>
										{/* <Button label="Cadastrar Senha" onClick={cadastrarSenha} /> */}
										<Button label="Cadastrar Senha" onClick={() => setDisplayConfirmation(true)} />
									</div>

									<div className="col-6" style={{ display: presencaSenha ? ' ' : 'none' }}>
										<Button label="Bater ponto" icon="pi pi-check" onClick={baterPonto} />
									</div>

									<div className="col-6 password-container">
										<button type="text" className="p-link" onClick={(e) => setDisplayPerdeuSenha(true)}>Perdeu a Senha?</button>
									</div>

									{/* <div className="col-12">
										<button type="text" className="p-link" onClick={(e) => setDisplayPerdeuSenha(true)}>Histórico de Batidas</button>
									</div> */}

									<div className="col-12" style={{ display: presencaSenha ? ' ' : 'none' }}>
										<Button className="p-button-warning mr-2 mt-1 mb-1" label="Histórico de Batidas" icon="pi pi-history" onClick={goHistoricoBatidas} />
									</div>

									<div className="col-12">
										<Messages ref={message} />
									</div>

									<div className="col-12" style={{ textAlign: 'center', display: linkComprovante ? ' ' : 'none' }}>
										<a href={linkComprovante} target="_blank" rel="noopener noreferrer">COMPROVANTE DE REGISTRO DE PONTO</a>
									</div>

									<Dialog header="Confirmação" visible={displayConfirmation} onHide={() => setDisplayConfirmation(false)} 
									style={{ width: '350px' }} modal footer={confirmationDialogFooter}>
										<div className="flex align-items-center justify-content-center">
											<i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
											<span>Guarde sua senha, será solicitada em todas as batidas. Tem certeza de que deseja continuar?</span>
										</div>
									</Dialog>

									<Dialog header="Perdeu a senha?" visible={displayPerdeuSenha} onHide={() => setDisplayPerdeuSenha(false)} style={{ width: '350px' }} modal footer={perdeuSenhaDialogFooter}>
										<div className="flex align-items-center justify-content-center">
											<i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
											<span>Solicite atualização de senha para o responsável pelo Controle de Ponto.</span>
										</div>
									</Dialog>

									<Dialog header="Cadastrar Senha" visible={displaySenha} closable={false} maximizable={false} maximized={true} modal style={{ width: '50vw' }} >
										<div className="field grid mb-2">
											<div className="col-12 md:col-12">
											</div>
											<Panel className="col-12 md:col-12 mt-2" header={<strong>{colaborador?.nome}</strong>}>
												<div className="p-fluid formgrid grid">
													<div className="field col-12 md:col-2 mb-2">
														<Password placeholder="Senha" toggleMask feedback={false} onChange={(e) => onInputChange(e, 'senha')} required className={classNames({ 'p-invalid': submitted && !model.senha })} />
														{submitted && !model.senha && <small className="p-invalid">Senha obrigatória</small>}
													</div>
													<div className="field col-12 md:col-2 mb-2">
														<Password placeholder="Repita a senha" toggleMask feedback={false} onChange={(e) => onInputChange(e, 'repitaSenha')} required className={classNames({ 'p-invalid': submitted && !model.repitaSenha })} />
														{submitted && !model.repitaSenha && <small className="p-invalid">Senha obrigatória</small>}
													</div>
													<div className="field col-12 md:col-2 mb-2">
														<Button label="Cadastrar" onClick={() => setDisplayConfirmation(true)} />
													</div>
													<div className="col-12">
														<Messages ref={messageDialog} />
													</div>
												</div>

											</Panel>
										</div>
									</Dialog>

									<Dialog header="Histórico de Batidas" visible={displayHistorico} onHide={() => setDisplayHistorico(false)} closable={true} maximizable={false} maximized={true} modal style={{ width: '50vw' }} >
										<div className="field grid mb-2">
											<div className="col-12 md:col-12">
											</div>
											<Panel className="col-12 md:col-12 mt-2" header={<strong>Período</strong>}>
												<div className="p-fluid formgrid grid">
													<label htmlFor="datainicial" className="col-12 md:col-1 md:mt-2">Data Inicial*</label>
													<div className="field col-12 md:col-2 mb-2">
														<Calendar dateFormat="dd/mm/yy" value={dataInicial} onChange={(e) => setDataInicial(e.value)} monthNavigator yearNavigator yearRange="2010:2050" showIcon required className={classNames({ 'p-invalid': submitted && !dataInicial })}></Calendar>
														{submitted && !dataInicial && <small className="p-invalid">Data Inicial obrigatória</small>}
													</div>

													<label htmlFor="dataFinal" className="col-12 md:col-1 md:mt-2">Data Final*</label>
													<div className="field col-12 md:col-2 mb-2">
														<Calendar dateFormat="dd/mm/yy" value={dataFinal} onChange={(e) => setDataFinal(e.value)} monthNavigator yearNavigator yearRange="2010:2050" showIcon required className={classNames({ 'p-invalid': submitted && !dataFinal })}></Calendar>
														{submitted && !dataFinal && <small className="p-invalid">Data Final obrigatória</small>}
													</div>

													<div className="field col-12 md:col-2 mb-2">
														<Button label="Gerar" onClick={historicoBatidas} />
													</div>
												</div>
											</Panel>
										</div>
									</Dialog>

								</div>
							</div>

						</div>
					</div>

				</div>
			</div >
		</div >

	);


	// return (
	// 	<a href={imageUrl} target="_blank" rel="noopener noreferrer">
	// 		Abrir mapa do OpenStreetMap
	// 	</a>
	// );

	// return <iframe width="100%" height="500" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" title="Map" src={imageUrl} />;
}

