import React, { useState, useRef } from 'react';
import api from "../service/api";
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Messages } from 'primereact/messages';
import Mensagens from '../globais/Mensagens';
import BTInativar from '../components/BTInativar';
import BTNovo from '../components/BTNovo';
import BTGravar from '../components/BTGravar';
import ConsultaGenerica from '../components/consultas/ConsultaGenerica';

const Unidades = () => {

    let emptyModel = {
        id: null,
        descricao: '',
    };

    const toast = useRef(null);
    const [model, setModel] = useState(emptyModel);
    const [lista, setLista] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const message = useRef();

    const colunas = [
        { field: 'descricao', header: 'Descrição', headerStyle: { minWidth: '15rem' } },
    ];

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <BTGravar setSubmitted={setSubmitted} cadastrar={cadastrar} />
                    <BTNovo setSubmitted={setSubmitted} setModel={setModel} emptyModel={emptyModel} />
                    <BTInativar inativar={inativar} />

                    <ConsultaGenerica
                        lista={lista}
                        model={model}
                        setModel={setModel}
                        onConsultar={consultar}
                        toast={toast}
                        colunas={colunas}
                        titulo="Consultar"
                        mensagemSelecao="Unidade Selecionada"
                        camposFiltroGlobal={['descricao']}
                        onModelSelecionado={onModelSelecionado}
                        life={3000}
                        largura='450px'
                    />

                    <Messages ref={message} />
                </div>
            </React.Fragment>
        )
    }

    const onModelSelecionado = (model) => {
        return `${model.descricao}`;
    };

    const onInputChange = (e, descricao) => {
        const val = (e.target && e.target.value) || '';
        let _model = { ...model };
        _model[`${descricao}`] = val;
        setModel(_model);
    }


    const cadastrar = () => {
        if (model.descricao.trim()) {
            if (model.id) {
                api
                    .put("/unidades/" + model.id, {
                        id: model.id,
                        descricao: model.descricao
                    })
                    .then(response => {
                        setModel(response.data);
                        addMessage("success", Mensagens.CADASTRO_ALTERAR);
                    })
                    .catch(erro => {
                        addMessage("error", Mensagens.ERRO);
                    })
            }
            else {
                api
                    .post("/unidades", {
                        id: model.id,
                        descricao: model.descricao
                    })
                    .then(response => {
                        setModel(response.data);
                        addMessage("success", Mensagens.CADASTRO_INCLUIR);
                    })
                    .catch(erro => {
                        addMessage("error", Mensagens.ERRO);
                    })
            }
        }
    }

    const inativar = () => {
        if (model.id) {
            api
                .delete("/unidades/" + model.id)
                .then(response => {
                    addMessage("success", Mensagens.CADASTRO_INATIVAR);
                    setSubmitted(false);
                    setModel(emptyModel);
                })
                .catch(erro => {
                    console.log(erro)
                    addMessage("error", Mensagens.ERRO);
                })
        } else {
            addMessage("warn", Mensagens.SELECIONE);
        }

    }

    const consultar = () => {
        api
            .get("/unidades")
            .then(response => setLista(response.data))
            .catch(() => {
                addMessage("error", Mensagens.ERRO);
            })
    }

    const addMessage = (sv, cn) => {
        message.current.show({ severity: sv, content: cn });
    };

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <div className="field grid p-fluid">
                        <label htmlFor="descricao" className="col-12 mb-2 md:col-1 md:mb-0">Descrição*</label>
                        <div className="col-12 md:col-11">
                            <InputText id="descricao" value={model.descricao} onChange={(e) => onInputChange(e, 'descricao')} required autoFocus className={classNames({ 'p-invalid': submitted && !model.descricao })} />
                            {submitted && !model.descricao && <small className="p-invalid">Descrição Obrigatória</small>}
                        </div>
                    </div>

                    <div className="col-12">
                        <Toast ref={toast} />
                        <Toolbar className="mb-2 " left={leftToolbarTemplate}></Toolbar>
                    </div>

                </div >
            </div >
        </div >
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Unidades, comparisonFn);
