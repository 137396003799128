import React, { useState, useEffect } from 'react';
import { classNames } from 'primereact/utils';
import { CSSTransition } from 'react-transition-group';
import { useHistory } from 'react-router-dom';
import api from './service/api';


const AppTopbar = (props) => {

    const history = useHistory();
    const [nomeUsuario, setNomeUsuario] = useState("Deslogado");
    const [nomeCliente, setNomeCliente] = useState("Sem Cliente");
    // const [nomeCliente, setNomeCliente] = useState(JSON.parse(localStorage.getItem("cliente")).nomeCliente);
    const [listaGestao, setListaGestao] = useState([]);

    let topbarItemsClassName = classNames('topbar-menu fadeInDown', { 'topbar-menu-visible': props.topbarMenuActive });

    useEffect(() => {
        const verificarUsuario = () => {
            if (localStorage.getItem("usuario")) {
                setNomeUsuario(JSON.parse(localStorage.getItem("usuario")).username);
                setNomeCliente(JSON.parse(localStorage.getItem("cliente")).nomeCliente);
            } else {
                setNomeUsuario("Deslogado");
                setNomeCliente("Sem Cliente");
                history.push("/login");
            }

        }
        verificarUsuario();
    }, [nomeUsuario, history]);

    const handleClick = (item) => {
        localStorage.setItem("cliente", JSON.stringify(item));
        window.location.href = "/";
    }

    const onTopbarItemClick = (event, item) => {
        if (props.onTopbarItemClick) {
            props.onTopbarItemClick({
                originalEvent: event,
                item: item
            });
        }
    };

    const onSair = () => {
        localStorage.removeItem("usuario");
        localStorage.removeItem("cliente");
        history.push("/login");
    }

    const goDashboard = () => {
        // window.location.href = '/';
        history.push("/");
    };

    const onGestao = () => {
        api
            .get("/usuarios/" + JSON.parse(localStorage.getItem("usuario")).id + "/clientes")
            .then(response => {
                if (!response.data) {
                    setListaGestao([{ nomeCliente: JSON.parse(localStorage.getItem("cliente")).nomeCliente }]);
                } else {
                    setListaGestao(response.data);
                }
            })
            .catch(() => {
                setListaGestao([]);
            })
    }

    const onConta = () => {
        history.push("/perfil/conta");
    }

    return (
        <div className="topbar clearfix">

            <div className="logo-link">
                <img className="logo" alt="logo" src="assets/layout/images/logo_he_principal.png" onClick={goDashboard} />
            </div>

            <button type="button" className="p-link menu-button" id="menu-button" onClick={props.onMenuButtonClick}>
                <i className="pi pi-align-left"></i>
            </button>

            <button type="button" className="p-link profile" onClick={props.onTopbarMenuButtonClick}>
                <span className="username">{nomeUsuario + " (" + nomeCliente.toUpperCase() + ")"}</span>
                {/* <img src="assets/layout/images/avatar/avatar3.png" alt="apollo-layout" /> */}
                <img src="assets/demo/images/galleria/semfoto.jpg" alt="apollo-layout" />
                <i className="pi pi-angle-down"></i>
            </button>

            {/* <span className="topbar-search">
                <InputText placeholder="Procurar" />
                <span className="pi pi-search"></span>
            </span> */}

            <ul className={topbarItemsClassName}>
                <li className={classNames({ 'menuitem-active': props.activeTopbarItem === 'gestao' })}
                    onClick={(e) => onTopbarItemClick(e, 'gestao')}>

                    <button type="button" className="p-link" onClick={onGestao}>
                        <i className="topbar-icon pi pi-fw pi-home"></i>
                        <span className="topbar-item-name">Gestão</span>
                    </button>

                    <ul>
                        {listaGestao.map((item, index) => (
                            <CSSTransition key={index} classNames="layout-submenu-container" timeout={{ enter: 400, exit: 400 }} in={props.activeTopbarItem === 'gestao'} unmountOnExit >
                                <li role="menuitem">
                                    <button type="button" className="p-link" onClick={() => handleClick(item)}>
                                        <i className={JSON.parse(localStorage.getItem("cliente")).id == item.id ? "pi pi-fw pi-bookmark-fill" : "pi pi-fw pi-bookmark"}></i>
                                        <span>{item.nomeCliente}</span>
                                    </button>
                                </li>
                            </CSSTransition>
                        ))}
                    </ul>
                </li>

                <li className={classNames({ 'menuitem-active': props.activeTopbarItem === 'profile' })}
                    onClick={(e) => onTopbarItemClick(e, 'profile')}>

                    <button type="button" className="p-link">
                        <i className="topbar-icon pi pi-fw pi-user"></i>
                        <span className="topbar-item-name">Perfil</span>
                    </button>

                    <CSSTransition classNames="layout-submenu-container" timeout={{ enter: 400, exit: 400 }} in={props.activeTopbarItem === 'profile'} unmountOnExit>
                        <ul>
                            <li role="menuitem">
                                <button type="button" className="p-link" onClick={onConta}>
                                    <i className="pi pi-fw pi-user-edit"></i>
                                    <span>Conta</span>
                                </button>
                            </li>
                            {/* <li role="menuitem">
                                <button type="button" className="p-link">
                                    <i className="pi pi-fw pi-eye"></i>
                                    <span>Privacy</span>
                                </button>
                            </li> */}
                            {/* <li role="menuitem">
                                <button type="button" className="p-link">
                                    <i className="pi pi-fw pi-cog"></i>
                                    <span>Settings</span>
                                </button>
                            </li> */}
                            <li role="menuitem">
                                <button type="button" className="p-link" onClick={onSair}>
                                    <i className="pi pi-fw pi-power-off"></i>
                                    <span>Sair</span>
                                </button>
                            </li>
                        </ul>
                    </CSSTransition>
                </li>

                {/* <li className={classNames({ 'menuitem-active': props.activeTopbarItem === 'settings' })}
                    onClick={(e) => onTopbarItemClick(e, 'settings')}>
                    <button type="button" className="p-link">
                        <i className="topbar-icon pi pi-fw pi-cog"></i>
                        <span className="topbar-item-name">Settings</span>
                    </button>

                    <CSSTransition classNames="layout-submenu-container" timeout={{ enter: 400, exit: 400 }} in={props.activeTopbarItem === 'settings'} unmountOnExit>
                        <ul>
                            <li role="menuitem">
                                <button type="button" className="p-link">
                                    <i className="pi pi-fw pi-palette"></i>
                                    <span>Change Theme</span>
                                    <span className="topbar-badge">1</span>
                                </button>
                            </li>
                            <li role="menuitem">
                                <button type="button" className="p-link">
                                    <i className="pi pi-fw pi-bookmark"></i>
                                    <span>Favorites</span>
                                </button>
                            </li>
                            <li role="menuitem">
                                <button type="button" className="p-link">
                                    <i className="pi pi-fw pi-lock"></i>
                                    <span>Lock Screen</span>
                                    <span className="topbar-badge">3</span>
                                </button>
                            </li>
                            <li role="menuitem">
                                <button type="button" className="p-link">
                                    <i className="pi pi-fw pi-image"></i>
                                    <span>Wallpaper</span>
                                </button>
                            </li>
                        </ul>
                    </CSSTransition>
                </li> */}
                <li className={classNames({ 'menuitem-active': props.activeTopbarItem === 'messages' })}
                    onClick={(e) => onTopbarItemClick(e, 'messages')}>
                    <button type="button" className="p-link">
                        <i className="topbar-icon pi pi-fw pi-envelope"></i>
                        <span className="topbar-item-name">Mensagens</span>
                        <span className="topbar-badge">1</span>
                    </button>
                    <CSSTransition classNames="layout-submenu-container" timeout={{ enter: 400, exit: 400 }} in={props.activeTopbarItem === 'messages'} unmountOnExit>
                        <ul>

                            <li role="menuitem">
                                <button type="button" className="p-link topbar-message">
                                    <img alt="Avatar 1" src="assets/layout/images/avatar/avatar4.png" width="35" />
                                    <span>Atendimento</span>
                                </button>
                            </li>



                            {/* <li role="menuitem">
                                <button type="button" className="p-link topbar-message">
                                    <img alt="Avatar 2" src="assets/layout/images/avatar/avatar2.png" width="35" />
                                    <span>Sales reports attached</span>
                                </button>
                            </li>
                            <li role="menuitem">
                                <button type="button" className="p-link topbar-message">
                                    <img alt="Avatar 3" src="assets/layout/images/avatar/avatar3.png" width="35" />
                                    <span>About your invoice</span>
                                </button>
                            </li>
                            <li role="menuitem">
                                <button type="button" className="p-link topbar-message">
                                    <img alt="Avatar 4" src="assets/layout/images/avatar/avatar2.png" width="35" />
                                    <span>Meeting today at 10pm</span>
                                </button>
                            </li>
                            <li role="menuitem">
                                <button type="button" className="p-link topbar-message">
                                    <img alt="Avatar 5" src="assets/layout/images/avatar/avatar4.png" width="35" />
                                    <span>Out of office</span>
                                </button>
                            </li> */}
                        </ul>
                    </CSSTransition>
                </li>
                {/* <li className={classNames({ 'menuitem-active': props.activeTopbarItem === 'notifications' })}
                    onClick={(e) => onTopbarItemClick(e, 'notifications')}>
                    <button type="button" className="p-link">
                        <i className="topbar-icon pi pi-fw pi-bell"></i>
                        <span className="topbar-item-name">Notifications</span>
                        <span className="topbar-badge">2</span>
                    </button>

                    <CSSTransition classNames="layout-submenu-container" timeout={{ enter: 400, exit: 400 }} in={props.activeTopbarItem === 'notifications'} unmountOnExit>
                        <ul>
                            <li role="menuitem">
                                <button type="button" className="p-link">
                                    <i className="pi pi-fw pi-list"></i>
                                    <span>Pending tasks</span>
                                </button>
                            </li>
                            <li role="menuitem">
                                <button type="button" className="p-link">
                                    <i className="pi pi-fw pi-calendar"></i>
                                    <span>Meeting today</span>
                                </button>
                            </li>
                            <li role="menuitem">
                                <button type="button" className="p-link">
                                    <i className="pi pi-fw pi-download"></i>
                                    <span>Download documents</span>
                                </button>
                            </li>
                            <li role="menuitem">
                                <button type="button" className="p-link">
                                    <i className="pi pi-fw pi-ticket"></i>
                                    <span>Book flight</span>
                                </button>
                            </li>
                        </ul>
                    </CSSTransition>
                </li> */}
            </ul>
        </div >
    );

}

export default AppTopbar;
