import React, { useState, useRef, useEffect } from 'react';
import api from "../service/api";
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Messages } from 'primereact/messages';
import Mensagens from '../globais/Mensagens';
import { MultiSelect } from 'primereact/multiselect';
import Botoes from '../globais/Botoes';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { AutoComplete } from 'primereact/autocomplete';
import { classNames } from 'primereact/utils';
import { InputMask } from 'primereact/inputmask';
import { InputSwitch } from 'primereact/inputswitch';

const RelatorioNotificacoesAjustes = () => {

    let emptyModel = {
        empresa: { id: 0, fantasia: '' },
        departamento: { id: 0, descricao: 'TODOS' },
        cargo: { id: 0, descricao: 'TODOS' },
        dataInicialS: '',
        dataFinalS: '',
        agruparColaboradores: false,
    };

    const toast = useRef(null);
    const [model, setModel] = useState(emptyModel);
    const [listaColaboradores, setListaColaboradores] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [multiselectValue, setMultiselectValue] = useState([]);
    const message = useRef();
    const [value, setValue] = useState(0);
    const interval = useRef(null);
    const [autoFilteredValueEmpresa, setAutoFilteredValueEmpresa] = useState([]);
    const [autoValueEmpresa, setAutoValueEmpresa] = useState(null);
    const [autoFilteredValueDepartamento, setAutoFilteredValueDepartamento] = useState([]);
    const [autoValueDepartamento, setAutoValueDepartamento] = useState(null);
    const [autoFilteredValueCargo, setAutoFilteredValueCargo] = useState([]);
    const [autoValueCargo, setAutoValueCargo] = useState(null);

    useEffect(() => {
        const buscaEmpresas = () => {
            api
                .get("/empresas")
                .then(response => {
                    setAutoValueEmpresa(response.data);
                    model.empresa = response.data[0];
                    buscaColaboradores(response.data[0], model.departamento, model.cargo);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }

        const buscaDepartamentos = () => {
            api
                .get("/departamentos")
                .then(response => setAutoValueDepartamento(response.data)
                )
                .catch(erro => {
                    addMessage("error", Mensagens.ERRO);
                })
        }

        const buscaCargos = () => {
            api
                .get("/cargos")
                .then(response => setAutoValueCargo(response.data)
                )
                .catch(erro => {
                    addMessage("error", Mensagens.ERRO);
                })
        }
        buscaEmpresas();
        buscaDepartamentos();
        buscaCargos();
    }, []);

    useEffect(() => {
        if (submitted) {
            let val = value;
            interval.current = setInterval(() => {
                val += Math.floor(Math.random() * 5) + 1;

                if (val >= 100) {
                    val = 97;
                    clearInterval(interval.current);
                }
                setValue(val);
            }, 6000);

            return () => {
                if (interval.current) {
                    clearInterval(interval.current);
                    interval.current = null;
                }
            }
        }
    }, [value]);

    const itemTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                {/* <span className={`mr-2 flag flag-${option.descricao.toLowerCase()}`} style={{ width: '18px', height: '12px' }} /> */}
                <span>{option.nome}</span>
            </div>
        );
    };

    const selectedItemTemplate = (option) => {
        if (option) {
            return (
                <div className="inline-flex align-items-center py-1 px-2 bg-primary text-primary border-round mr-2">
                    {/* <span className={`mr-2 flag flag-${option.descricao.toLowerCase()}`} style={{ width: '18px', height: '12px' }} /> */}
                    <span>{option.nome}</span>
                </div>
            );
        }

        return 'Selecione Colaboradores';
    };


    const buscaColaboradores = (empresa, departamento, cargo) => {
        if (empresa !== null) {
            api
                .get("/colaboradores?empresa=" + empresa.id + "&departamento=" + departamento.id + "&cargo=" + cargo.id)
                .then(response => {
                    setListaColaboradores(response.data)
                })
                .catch(erro => {
                    addMessage("error", Mensagens.ERRO);
                })
        }
    };

    const onInputChange = (e, descricao) => {
        const val = (e.target && e.target.value) || '';
        let _model = { ...model };
        _model[`${descricao}`] = val;
        setModel(_model);
        if (descricao === 'empresa' || descricao === 'departamento' || descricao === 'cargo') {
            setMultiselectValue([]);
            buscaColaboradores(_model.empresa, _model.departamento, _model.cargo);
        }
    }

    const procurarEmpresas = (event) => {
        setTimeout(() => {
            try {
                if (!event.query.trim().length) {
                    setAutoFilteredValueEmpresa([...autoValueEmpresa]);
                }
                else {
                    setAutoFilteredValueEmpresa(autoValueEmpresa.filter((em) => {
                        return em.fantasia.toLowerCase().startsWith(event.query.toLowerCase());

                    }));
                }
            } catch (e) {
            }

        }, 250);
    };

    const procurarDepartamentos = (event) => {
        setTimeout(() => {
            try {
                if (!event.query.trim().length) {
                    setAutoFilteredValueDepartamento([...autoValueDepartamento]);
                }
                else {
                    setAutoFilteredValueDepartamento(autoValueDepartamento.filter((em) => {
                        return em.descricao.toLowerCase().startsWith(event.query.toLowerCase());

                    }));
                }
            } catch (e) {
            }

        }, 250);
    };

    const procurarCargos = (event) => {
        setTimeout(() => {
            try {
                if (!event.query.trim().length) {
                    setAutoFilteredValueCargo([...autoValueCargo]);
                }
                else {
                    setAutoFilteredValueCargo(autoValueCargo.filter((em) => {
                        return em.descricao.toLowerCase().startsWith(event.query.toLowerCase());

                    }));
                }
            } catch (e) {
            }

        }, 250);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label={Botoes.VISUALIZAR} icon="pi pi-check" onClick={visualizar} className="p-button-success mr-2" />
                    <Messages ref={message} />
                </div>
            </React.Fragment>
        )
    }

    const visualizar = () => {
        setSubmitted(true);
        if (model.dataInicialS.trim() && model.dataFinalS.trim()) {
            if (multiselectValue.length > 0) {
                setValue(6);
                api
                    .post("/relatorio/notificacaoajustes", {
                        colaboradores: multiselectValue,
                        empresa: model.empresa,
                        dataInicialS: model.dataInicialS,
                        dataFinalS: model.dataFinalS,
                        colaboradoresDesligados: false,
                        agruparColaboradores: model.agruparColaboradores,
                    }, { responseType: 'arraybuffer' })
                    .then(response => {

                        const file = new Blob(
                            [response.data],
                            { type: 'application/pdf' });

                        const fileURL = URL.createObjectURL(file);

                        window.open(fileURL);
                        setSubmitted(false);
                        setValue(100);
                    })
                    .catch(() => {
                        addMessage("error", Mensagens.ERRO);
                        setSubmitted(false);
                        setValue(0);
                    })

            } else {
                setSubmitted(false);
                setValue(0);
                addMessage("warn", Mensagens.SELECIONE);
            }
        }
    }

    const onSwitchChange = (e, descricao) => {
        const val = e.value || '';
        let _model = { ...model };
        _model[`${descricao}`] = val;
        setModel(_model);
    }

    const addMessage = (sv, cn) => {
        message.current.show({ severity: sv, content: cn });
    };

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="empresa" className="col-12 md:col-1 md:mb-0">Empresa*</label>
                        <div className="col-12 md:col-11">
                            <AutoComplete placeholder="Procure" id="empresa" dropdown value={model.empresa} onChange={(e) => onInputChange(e, 'empresa')} suggestions={autoFilteredValueEmpresa} completeMethod={procurarEmpresas} field="fantasia" required className={classNames({ 'p-invalid': submitted && !model.empresa })} />
                            {submitted && !model.empresa && <small className="p-invalid">Empresa Obrigatória</small>}
                        </div>
                    </div>

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="departamento" className="col-12 md:col-1 md:mb-0">Departamento</label>
                        <div className="col-12 md:col-11">
                            <AutoComplete placeholder="Procure" id="departamento" dropdown value={model.departamento} onChange={(e) => onInputChange(e, 'departamento')} suggestions={autoFilteredValueDepartamento} completeMethod={procurarDepartamentos} field="descricao" />
                        </div>
                    </div>

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="cargo" className="col-12 md:col-1 md:mb-0">Cargo</label>
                        <div className="col-12 md:col-11">
                            <AutoComplete placeholder="Procure" id="cargo" dropdown value={model.cargo} onChange={(e) => onInputChange(e, 'cargo')} suggestions={autoFilteredValueCargo} completeMethod={procurarCargos} field="descricao" />
                        </div>
                    </div>

                    <div className="p-fluid formgrid grid">
                        <label htmlFor="datainicial" className="col-12 md:col-1 md:mt-2">Data Inicial*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputMask id='datainicial' mask='99/99/9999' value={model.dataInicialS} onChange={e => onInputChange(e, 'dataInicialS')} required className={classNames({ 'p-invalid': submitted && !model.dataInicialS })} />
                            {submitted && !model.dataInicialS && <small className="p-invalid">Data Obrigatória</small>}
                        </div>
                        <label htmlFor="dataFinal" className="col-12 md:col-1 md:mt-2">Data Final*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputMask id='dataFinal' mask='99/99/9999' value={model.dataFinalS} onChange={e => onInputChange(e, 'dataFinalS')} required className={classNames({ 'p-invalid': submitted && !model.dataFinalS })} />
                            {submitted && !model.dataFinalS && <small className="p-invalid">Data Obrigatória</small>}
                        </div>
                    </div>

                    <div className="p-fluid formgrid grid">
                        <div className="field col-12 md:col-12 mb-2">
                            <MultiSelect value={multiselectValue} onChange={(e) => setMultiselectValue(e.value)} options={listaColaboradores} optionLabel="nome" filter
                                itemTemplate={itemTemplate} selectedItemTemplate={selectedItemTemplate} maxSelectedLabels={5} />
                        </div>
                    </div>

                    <div className="field grid p-fluid">
                        <label htmlFor="descontaExtra" className="col-12 mb-2 md:col-2 md:mb-0">Agrupar por Colaboradores?</label>
                        <div className="col-12 md:col-10">
                            <InputSwitch checked={model.agruparColaboradores} onChange={(e) => onSwitchChange(e, 'agruparColaboradores')} />
                        </div>
                    </div>

                    <div className="col-12">
                        <Toast ref={toast} />
                        <Toolbar className="mb-2 " left={leftToolbarTemplate}></Toolbar>
                    </div>


                    <div className="col-12">
                        <div className="col">
                            <ProgressBar value={value} />
                        </div>
                    </div>

                </div >
            </div >
        </div >
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(RelatorioNotificacoesAjustes, comparisonFn);
