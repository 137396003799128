import React, { useState, useRef, useEffect } from 'react';
import api from "../service/api";
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Messages } from 'primereact/messages';
import Mensagens from '../globais/Mensagens';
import { MultiSelect } from 'primereact/multiselect';
import Botoes from '../globais/Botoes';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';

const RelatorioEmpresas = () => {

    const toast = useRef(null);
    const [lista, setLista] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [multiselectValue, setMultiselectValue] = useState([]);
    const message = useRef();
    const [value, setValue] = useState(0);
    const interval = useRef(null);

    useEffect(() => {
        const busca = () => {
            api
                .get("/empresas")
                .then(response => setLista(response.data))
                .catch(erro => {
                    addMessage("error", Mensagens.ERRO);
                })
        }
        busca();
    }, []);

    useEffect(() => {
        if (submitted) {
            let val = value;
            interval.current = setInterval(() => {
                val += Math.floor(Math.random() * 10) + 1;

                if (val >= 100) {
                    val = 97;
                    clearInterval(interval.current);
                }
                setValue(val);
            }, 2000);

            return () => {
                if (interval.current) {
                    clearInterval(interval.current);
                    interval.current = null;
                }
            }
        }
    }, [value, submitted]);

    const itemTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                {/* <span className={`mr-2 flag flag-${option.descricao.toLowerCase()}`} style={{ width: '18px', height: '12px' }} /> */}
                <span>{option.fantasia}</span>
            </div>
        );
    };

    const selectedItemTemplate = (option) => {
        if (option) {
            return (
                <div className="inline-flex align-items-center py-1 px-2 bg-primary text-primary border-round mr-2">
                    {/* <span className={`mr-2 flag flag-${option.descricao.toLowerCase()}`} style={{ width: '18px', height: '12px' }} /> */}
                    <span>{option.fantasia}</span>
                </div>
            );
        }

        return 'Selecione Empresas';
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label={Botoes.VISUALIZAR} icon="pi pi-check" onClick={visualizar} className="p-button-success mr-2" />
                    <Messages ref={message} />
                </div>
            </React.Fragment>
        )
    }

    const visualizar = () => {
        if (multiselectValue.length > 0) {
            setValue(1);
            setSubmitted(true);
            api
                .post("/empresas/relatorio", {
                    selecionados: multiselectValue,
                }, { responseType: 'arraybuffer' })
                .then(response => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });

                    const fileURL = URL.createObjectURL(file);

                    window.open(fileURL);
                    setSubmitted(false);
                    setValue(100);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                    setSubmitted(false);
                    setValue(0);
                })

        } else {
            setSubmitted(false);
            setValue(0);
            addMessage("warn", Mensagens.SELECIONE);
        }
    }

    const addMessage = (sv, cn) => {
        message.current.show({ severity: sv, content: cn });
    };

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">

                    <div className="p-fluid formgrid grid">
                        <div className="field col-12 md:col-12 mb-2">
                            <MultiSelect value={multiselectValue} onChange={(e) => setMultiselectValue(e.value)} options={lista} optionLabel="fantasia" filter
                                itemTemplate={itemTemplate} selectedItemTemplate={selectedItemTemplate} maxSelectedLabels={5} />
                        </div>
                    </div>

                    <div className="col-12 ">
                        <Toast ref={toast} />
                        <Toolbar className="mb-2 " left={leftToolbarTemplate}></Toolbar>
                    </div>

                    <div className="col-12">
                        <div className="col">
                            <ProgressBar value={value} />
                        </div>
                    </div>

                </div >
            </div >
        </div >
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(RelatorioEmpresas, comparisonFn);
