export default class Mensagens {
    static CADASTRO_INCLUIR = "Cadastro realizado com sucesso!";
    static CADASTRO_ALTERAR = "Alteração realizada com sucesso!";
    static CADASTRO_INATIVAR = "Inativação realizado com sucesso!";
    static CADASTRO_ATIVAR = "Ativação realizado com sucesso!";

    static SELECIONE = "Selecione um elemento...";
    static SELECIONE_COLABORADOR_INATIVAR = "Selecione um colaborador e informe a data do desligamento...";
    static SELECIONE_BH = "Selecione um Banco de Horas Iniciado...";

    static SELECIONE_COLABORADOR = "Selecione um colaborador...";
    static HORARIO_VALIDO = "Inclua um valor válido...";

    static ERRO = "Algum erro aconteceu...";
    static REPNAOCADASTRADO = "Número de série não cadastrado...";

    static MENSAGEM_PONTOFECHADOEMBANCODEHORAS = "Atenção! As informações do período informado estão bloqueadas por Banco de Horas fechado..."


    static SELECIONEOPERACAO = "Selecione uma operação...";
    static INCLUAMOTIVO = "Inclua um motivo...";
    static INCLUAHORARIO = "Inclua um novo horário...";
    static SELECIONEBATIDASDESCONSIDERAR = "Clique nas batidas que deseja desconsiderar..."
    static SELECIONEOPERACAODESCONSIDERAR = "Selecione operação Desconsiderar...";

    static OPERACAO_SUCESSO = "Operação realizada com sucesso!";
    static JUSTIFICATIVA_DUPLICADA = "Já existe justificativa no período...";

    static VERIFIQUE_SENHA = "A senha deve possuir pelo menos 6 caracteres...";
    static SENHA_CRIADA = "Senha criada com sucesso!";

    static SENHAS_DIFERENTES = "As senhas não são iguais..."

    static FORA_AREA_PONTO = "Você não está dentro da área permitida..."

    static SENHA_INVALIDA = "Senha atual inválida..."
    static SEM_USUARIO = "Sem usuário definido...";
    static SENHA_NOVA_INVALIDA = "Senha nova inválida..."

    static SENHA_ERRADA = "Verifique a senha..."

    static AVISO_COLABORADORES_CPF = "São listados somente colaboradores com CPF cadastrado."
    static AVISO_EMPRESA_BATIDA_VIALINK = "São listadas somente empresas com Batida Via Link (REP-P) cadastrada."

    static AVISO_COLABORADOR_INICIADO = "Saldo do colaborador já foi iniciado..."

    static BH_RELATORIO_SEMFECHAMENTOMES = "O Mês selecionado é superior ao vigente. \n "
        + "Realize o fechamento para garantir cálculos corretos.";

    static BH_RELATORIO_BHNAOINICIADO = "Atenção! Deve-se Iniciar Saldo de Algum Colaborador Para Iniciar o Banco de Horas.";

    static MENSAGEM_BHBANCOFORAPERIODO = "O Colaborador Não Possui Informações de Saldo Para o Mês Selecionado. \n"
            + "Possíveis Motivos: \n"
            + "1) Caso Mês Inferior ao Atual: Colaborador Foi Incluído Em Mês Posterior ao Selecionado e/ou Seu Saldo Não Foi Iniciado. \n"
            + "2) Caso Mês Superior ao Atual: Não Foi Efetuado o Fechamento de Mês, Verifique o Mês Vigente.";

}
