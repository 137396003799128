import React, { useState, useRef, useEffect } from 'react';
import api from "../service/api";
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Messages } from 'primereact/messages';
import Mensagens from '../globais/Mensagens';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { FileUpload } from 'primereact/fileupload';
import { SelectButton } from 'primereact/selectbutton';
import { ProgressBar } from 'primereact/progressbar';
import DadosApi from '../globais/DadosApi';

const ImportacaoRep = () => {

    let emptyModel = {
        dataImportacao: '',
        horarioImportacao: '',
    };

    const toast = useRef(null);
    const [model, setModel] = useState(emptyModel);
    const [uploadRealizado, setUploadRealizado] = useState(false);
    const [tipoCarregamento, settipoCarregamento] = useState('Importação');
    const options = ['Importação', 'Reimportação'];
    const [url, setUrl] = useState(DadosApi.CAMINHO_API+"/importaafd/importar");
    const [submitted, setSubmitted] = useState(false);
    const [value, setValue] = useState(0);
    const interval = useRef(null);

    const message = useRef();

    useEffect(() => {
        const buscaDadosImportacao = () => {
            api
                .get("/importaafd")
                .then(response => {
                    let _model = { ...model };
                    _model.dataImportacao = response.data.dataImportacaoS;
                    _model.horarioImportacao = response.data.horarioImportacaoS;
                    setModel(_model);
                }).catch(() => {
                    addMessage("error", Mensagens.ERRO);
                });
        }
        buscaDadosImportacao();
    }, [uploadRealizado]);

    useEffect(() => {
        if (submitted) {
            let val = value;
            interval.current = setInterval(() => {
                val += Math.floor(Math.random() * 2) + 1;

                if (val >= 100) {
                    val = 97;
                    clearInterval(interval.current);
                }
                setValue(val);
            }, 6000);

            return () => {
                if (interval.current) {
                    clearInterval(interval.current);
                    interval.current = null;
                }
            }
        }
    }, [value, submitted]);

    const addMessage = (sv, cn) => {
        message.current.show({ severity: sv, content: cn });
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    {/* <BTGravar setSubmitted={setSubmitted} cadastrar={cadastrar} />
                    <BTNovo setSubmitted={setSubmitted} setModel={setModel} emptyModel={emptyModel} />
                    <BTInativar inativar={inativar} /> */}

                    <Messages ref={message} />
                </div>
            </React.Fragment>
        )
    }

    const onError = (erro) => {
        if (erro.xhr.status === 406) {
            addMessage("error", Mensagens.REPNAOCADASTRADO);
        } else {
            addMessage("error", Mensagens.ERRO);
        }
    }

    const beforeRequest = (r) => {
        const usuario = JSON.parse(localStorage.getItem('usuario'));
        const cliente = JSON.parse(localStorage.getItem("cliente"));
        r.xhr.setRequestHeader('Cliente-id', cliente ? '' + cliente.id : {});
        r.xhr.setRequestHeader("Authorization", usuario && usuario.token ? 'Bearer ' + usuario.token : {});
        setSubmitted(true);
        setValue(3);
    }


    const onUpload = () => {
        setUploadRealizado(true);
        setValue(100);
        setSubmitted(false);
        addMessage("success", Mensagens.OPERACAO_SUCESSO);
        // toast.current.show({ severity: 'info', summary: 'Sucesso', detail: Mensagens.OPERACAO_SUCESSO});
    }

    const onTipo = (e) => {
        if (e.value === 'Importação') {
            setUrl(DadosApi.CAMINHO_API+"/importaafd/importar");
        } else {
            setUrl(DadosApi.CAMINHO_API+"/importaafd/reimportar");
        }
        settipoCarregamento(e.value);
    };

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <Accordion activeIndex={1} >
                        <AccordionTab header="AFD no REP">
                        </AccordionTab>
                        <AccordionTab header="AFD Local">

                            <div className="col-12">
                                <SelectButton value={tipoCarregamento} options={options} onChange={(e) => onTipo(e)} />
                            </div>

                            <div className="col-12">
                                <FileUpload name="files" url={url} onBeforeSend={(xhr) => beforeRequest(xhr)} onUpload={onUpload} multiple accept=".txt" maxFileSize={1000000000}
                                    onError={erro => onError(erro)} chooseLabel="Procurar" uploadLabel='Carregar' cancelLabel='Cancelar' invalidFileSizeMessageDetail='Tamanho do arquivo superior a 10mb.' invalidFileSizeMessageSummary=' ' />

                                {/* <FileUpload name="files" url={url} onUpload={onUpload} multiple accept=".txt" maxFileSize={1000000000}
                                    onError={erro => onError(erro)} chooseLabel="Procurar" uploadLabel='Carregar' cancelLabel='Cancelar' invalidFileSizeMessageDetail='Tamanho do arquivo superior a 10mb.' invalidFileSizeMessageSummary=' ' />
                            */}

                            </div>

                            <div className="col-12">
                                <label htmlFor='importacao'>Importado até {model.dataImportacao} às {model.horarioImportacao}.</label>
                            </div>

                            <div className="col-12">
                                <div className="col">
                                    <ProgressBar value={value} />
                                </div>
                            </div>
                        </AccordionTab>

                    </Accordion>

                    <Messages ref={message} />




                </div >
            </div >
        </div >
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ImportacaoRep, comparisonFn);
