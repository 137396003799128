import React, { useState, useRef, useEffect } from 'react';
import api from "../service/api";
import { Messages } from 'primereact/messages';
import Mensagens from '../globais/Mensagens';
import { Fieldset } from 'primereact/fieldset';
import { TabMenu } from 'primereact/tabmenu';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';
import { Password } from 'primereact/password';
import Formatacao from '../utilities/Formatacao';
import { Divider } from 'primereact/divider';
import apiAutenticacao from '../service/apiAutenticacao';
import { Dialog } from 'primereact/dialog';

const PerfilContaUsuarioConfiguracoes = () => {

    let usuarioModel = {
        id: null,
        nomeUsuario: JSON.parse(localStorage.getItem('usuario')).username,
        dataCadastroS: '',
        email: '',
        telefone: '',
        nome: '',
        dataAlteracaoSenhaS: '',
        dataBloqueioS: '',
        senhaAtual: '',
        senhaNova: '',
        senhaRepetida: '',
    };

    const toast = useRef(null);
    const [modelUsuario, setModelUsuario] = useState(usuarioModel);
    const [submitted, setSubmitted] = useState(false);
    const [visibilidadeCamposSenha, setVisibilidadeCamposSenha] = useState(false);
    const [emailValido, setEmailValido] = useState(false);
    const [senhaValida, setSenhaValida] = useState(false);
    const [senhaRepetidaValida, setSenhaRepetidaValida] = useState(false);
    const [presencaMinuscula, setPresencaMinuscula] = useState(false);
    const [presencaMaiuscula, setPresencaMaiuscula] = useState(false);
    const [presencaNumero, setPresencaNumero] = useState(false);
    const [presencaMinimoCaracteres, setPresencaMinimoCaracteres] = useState(false);
    const [senhaRepetidaDigitada, setSenhaRepetidaDigitada] = useState(false);
    const [displayContato, setDisplayContato] = useState(false);
    const messageSenha = useRef();
    const history = useHistory();

    const [activeIndex, setActiveIndex] = useState(0);

    const items = [
        { label: 'Configurações', icon: 'pi pi-fw pi-cog' },
        { label: 'Assinatura', icon: 'pi pi-fw pi-money-bill' }
    ];

    let formatacao = new Formatacao();

    const footer = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">Obrigatório</p>
            <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
                <li style={{ color: presencaMinuscula ? 'green' : 'red' }}>Pelo menos uma minúscula</li>
                <li style={{ color: presencaMaiuscula ? 'green' : 'red' }}>Pelo menos uma maiúscula</li>
                <li style={{ color: presencaNumero ? 'green' : 'red' }}>Pelo menos um número</li>
                <li style={{ color: presencaMinimoCaracteres ? 'green' : 'red' }}>Mínimo de 8 caracteres</li>
            </ul>
        </React.Fragment>
    );


    useEffect(() => {
        const buscaInformacoes = () => {
            api
                .get("/perfil/conta/configuracoes")
                .then(response => {
                    setModelUsuario(response.data);
                }
                )
                .catch(() => {
                    alert(Mensagens.ERRO);
                })
        }
        buscaInformacoes();
    }, []);


    const onInputChange = (e, descricao) => {
        const val = (e.target && e.target.value) || '';
        let _model = { ...modelUsuario };
        _model[`${descricao}`] = val;
        setModelUsuario(_model);

        if (descricao === 'senhaNova') {
            validateSenha(_model.senhaNova);
            validateRepetirSenha(_model.senhaNova, _model.senhaRepetida);
        }
        if (descricao === 'senhaRepetida') {
            setSenhaRepetidaDigitada(true);
            validateRepetirSenha(_model.senhaNova, _model.senhaRepetida);
        }
    }

    const addMessageSenha = (sv, cn) => {
        messageSenha.current.show({ severity: sv, content: cn });
    };

    const onTabMudanca = (event) => {
        console.log(event)
        if (event.index === 0) {
            history.push("/perfil/conta/configuracoes");
        } else if (event.index === 1) {
            history.push("/perfil/conta/assinatura");
        }
    }

    const onAlterarSenha = () => {
        setVisibilidadeCamposSenha(true);
    }

    const onCancelarSenha = () => {
        setVisibilidadeCamposSenha(false);
    }

    const onGravarSenha = () => {
        if (modelUsuario.id) {
            apiAutenticacao
                .post("/entrar", {
                    nomeUsuario: modelUsuario.nomeUsuario,
                    senha: modelUsuario.senhaAtual,
                })
                .then(() => {
                    alterarDefinitivamenteSenha();
                })
                .catch((erro) => {
                    if (erro.response) {
                        addMessageSenha("error", Mensagens.SENHA_INVALIDA);
                    } else {
                        addMessageSenha("error", Mensagens.ERRO);
                    }
                })
        } else {
            addMessageSenha("error", Mensagens.SEM_USUARIO);
            // alert("Sem usuário");
        }
    }

    const alterarDefinitivamenteSenha = () => {
        if (senhaValida && senhaRepetidaValida) {
            if (modelUsuario.id) {
                apiAutenticacao
                    .put("/alterar", {
                        id: modelUsuario.id,
                        senha: modelUsuario.senhaNova,
                    })
                    .then(() => {
                        addMessageSenha("success", Mensagens.OPERACAO_SUCESSO);
                    })
                    .catch((erro) => {
                        if (erro.response) {
                            addMessageSenha("error", erro.response.data.message.message);
                        } else {
                            addMessageSenha("error", Mensagens.ERRO);
                        }
                    })
            }
        } else {
            addMessageSenha("error", Mensagens.SENHA_NOVA_INVALIDA);
        }
    }

    const validateSenha = (senha) => {
        setPresencaMinuscula(false);
        setPresencaMaiuscula(false);
        setPresencaNumero(false);
        setPresencaMinimoCaracteres(false);

        let minuscula = /^(?=.*[a-z]).*$/;
        let maiuscula = /^(?=.*[A-Z]).*$/;
        let numero = /^(?=.*\d).*$/;
        let minimoCaracteres = senha.trim().length >= 8;

        const senhaRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/;

        if (minuscula.test(senha)) {
            setPresencaMinuscula(true)
        }
        if (maiuscula.test(senha)) {
            setPresencaMaiuscula(true);
        }
        if (numero.test(senha)) {
            setPresencaNumero(true);
        }
        if (minimoCaracteres) {
            setPresencaMinimoCaracteres(true);
        }

        const isSenhaValida = senha.trim().length >= 8 && senhaRegex.test(senha);

        setSenhaValida(isSenhaValida);
    }

    const validateRepetirSenha = (senhaAtual, senhaRepetidaAtual) => {
        setSenhaRepetidaValida(false);
        if (senhaAtual === senhaRepetidaAtual) {
            setSenhaRepetidaValida(true);
        }
    }

    const contatoDialogFooter = (
		<>
			<Button type="button" label="Entendi" icon="pi pi-thumbs-up" onClick={() => setDisplayContato(false)} className="p-button-text" autoFocus />
		</>
	)

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">

                    <h5>Minha Conta</h5>

                    <TabMenu className='mb-2' model={items} activeIndex={activeIndex} onTabChange={onTabMudanca} />

                    <Fieldset legend="Informacões da Conta" className='mb-2'>
                        <div className="grid">
                            <div className="col-12">
                                <div style={{ marginBottom: '0' }}>
                                    <p style={{ margin: '0' }}>Usuário</p>
                                </div>
                                <div>
                                    <h5 style={{ margin: '0' }}><strong>{modelUsuario?.nomeUsuario}</strong></h5>
                                </div>
                            </div>

                            <div className="col-12">
                                <div style={{ marginBottom: '0' }}>
                                    <p style={{ margin: '0' }}>Nome</p>
                                </div>
                                <div>
                                    <h5 style={{ margin: '0' }}><strong>{modelUsuario?.nome}</strong></h5>
                                </div>
                            </div>

                            <div className="col-12">
                                <div style={{ marginBottom: '0' }}>
                                    <p style={{ margin: '0' }}>Usuário Desde</p>
                                </div>
                                <div>
                                    <h5 style={{ margin: '0' }}><strong>{modelUsuario?.dataCadastroS}</strong></h5>
                                </div>
                            </div>
                        </div>
                    </Fieldset>

                    {/* <Fieldset legend="Informacões da conta" className='mb-2'>
                        <div className="grid">
                            <div className="col-12">
                                <label htmlFor="usuario" className="mb-2 md:col-1 md:mb-0">Usuário:</label>
                                <label htmlFor="usuario" style={{ fontSize: '18px', marginLeft: '5px' }} className="mb-2 md:col-1 md:mb-0"><strong>{modelUsuario?.nomeUsuario}</strong></label>
                            </div>
                            <div className="col-12">
                                <label htmlFor="datacadastro" className="mb-2 md:col-1 md:mb-0">Usuário desde:</label>
                                <label htmlFor="datacadastro" style={{ fontSize: '18px', marginLeft: '5px' }} className="mb-2 md:col-1 md:mb-0"><strong>{modelUsuario?.dataCadastro}</strong></label>
                            </div>
                        </div>
                    </Fieldset> */}

                    <Fieldset legend="Email e Telefone" className='mb-2'>
                        <div className="grid">
                            <div className="col-6 md:col-6">
                                <div style={{ marginBottom: '0' }}>
                                    <p style={{ margin: '0' }}>Email</p>
                                </div>
                                <div>
                                    <h5 style={{ margin: '0' }}><strong>{modelUsuario?.email}</strong></h5>
                                </div>
                            </div>

                            <div className="col-6 md:col-6 mt-0 md:mt-0 text-right">
                                <Button icon="pi pi-pencil" className="p-button-text" label='Alterar' onClick={(e) => setDisplayContato(true)} />
                            </div>

                            <div className="col-6 md:col-6">
                                <div style={{ marginBottom: '0' }}>
                                    <p style={{ margin: '0' }}>Telefone</p>
                                </div>
                                <div>
                                    <h5 style={{ margin: '0' }}><strong>{formatacao.formatarTelefone(modelUsuario?.telefone)}</strong></h5>
                                </div>
                            </div>
                            <div className="col-6 md:col-6 mt-0 md:mt-0 text-right">
                                <Button icon="pi pi-pencil" className="p-button-text" label='Alterar' onClick={(e) => setDisplayContato(true)} />
                            </div>
                        </div>
                    </Fieldset>

                    {/* <Fieldset legend="Email e celular" className='mb-2'>
                        <div className="grid">
                            <div className="col-3 md:col-6">
                                <label htmlFor="email" className="mb-0 mt-0 md:col-1 md:mb-0">Email:</label>
                                <label htmlFor="email" style={{ fontSize: '18px', marginLeft: '5px' }} className="mb-2 md:col-1 md:mb-0"><strong>{modelUsuario?.email}</strong></label>
                            </div>
                            <div className="col-9 md:col-6 mt-0 md:mt-0 text-right">
                                <Button icon="pi pi-pencil" className="p-button-text" label='Alterar' />
                            </div>
                            <div className="col-3 md:col-6">
                                <label htmlFor="celular" className="mb-0 mt-0 md:col-1">Celular:</label>
                                <label htmlFor="celular" style={{ fontSize: '18px', marginLeft: '5px' }} className="mb-2 md:col-1 md:mb-0"><strong>{modelUsuario?.celular}</strong></label>
                            </div>
                            <div className="col-9 md:col-6 mt-0 md:mt-0 text-right">
                                <Button icon="pi pi-pencil" className="p-button-text" label='Alterar' />
                            </div>
                        </div>
                    </Fieldset> */}

                    <Fieldset legend="Senha" className='mb-2'>
                        <div className="grid">
                            <div style={{ display: visibilidadeCamposSenha ? 'none' : ' ' }} className="col-3 md:col-6">
                                <div style={{ marginBottom: '2' }}>
                                    <h5 style={{ margin: '0', fontSize: '21px' }}><strong>●●●●●●●●●●●●●●</strong></h5>
                                </div>
                                {/* <div>
                                    <p style={{ margin: '0' }}>Sua senha foi alterada pela última vez há XXXX.</p>
                                </div> */}
                            </div>

                            <div style={{ display: visibilidadeCamposSenha ? 'none' : ' ' }} className="col-9 md:col-6 mt-0 md:mt-0 text-right" onClick={onAlterarSenha}>
                                <Button icon="pi pi-pencil" className="p-button-text" label='Alterar' />
                            </div>

                            <div style={{ display: visibilidadeCamposSenha ? '' : 'none' }} className="col-12">
                                <Password id="senhaatual" placeholder="Senha atual*" toggleMask feedback={false} onChange={(e) => onInputChange(e, 'senhaAtual')} />
                            </div>

                            <div style={{ display: visibilidadeCamposSenha ? '' : 'none' }} className="col-12">
                                <Password id="senhanova" placeholder="Nova senha*" toggleMask weakLabel="Fraca" mediumLabel='Média' strongLabel='Forte' promptLabel='Digite a senha' footer={footer} onChange={(e) => onInputChange(e, 'senhaNova')} />
                            </div>

                            <div style={{ display: visibilidadeCamposSenha ? '' : 'none' }} className="col-12" onChange={(e) => onInputChange(e, "senhaRepetida")}>
                                <Password id="senhanovarepetir" placeholder="Confirme a nova senha*" toggleMask feedback={false} />
                            </div>

                            <div style={{ display: visibilidadeCamposSenha ? ' ' : 'none' }} className="col-12 md:col-6 mt-0 md:mt-0 text-left">
                                <Button icon="pi pi-plus" className="p-button-success mr-2 mt-1 mb-1" label='Gravar' onClick={onGravarSenha} />
                                <Button icon="pi pi-minus" className="p-button-warning mr-2 mt-1 mb-1" label='Cancelar' onClick={onCancelarSenha} />
                            </div>

                            <div style={{ display: visibilidadeCamposSenha ? 'none' : ' ' }} className="col-12">
                                <label htmlFor="senhaalterada" className="mb-2 md:col-1 md:mb-0">{modelUsuario.dataAlteracaoSenhaS === null ? "Sua senha nunca foi alterada." : "Sua senha foi alterada pela última vez em " + modelUsuario?.dataAlteracaoSenhaS}</label>
                            </div>

                            <div style={{ display: visibilidadeCamposSenha ? ' ' : 'none' }} className='col-12'>
                                <Messages ref={messageSenha} />
                            </div>

                        </div>
                    </Fieldset>

                    {/* <Fieldset legend="Senha" className='mb-2'>
                        <div className="grid">
                            <div className="col-3 md:col-6">
                                <label htmlFor="senha" style={{ fontSize: '21px' }} className="mb-2 md:col-1 md:mb-0">●●●●●●●●●●●●●●</label>
                            </div>
                            <div className="col-9 md:col-6 mt-0 md:mt-0 text-right">
                                <Button icon="pi pi-pencil" className="p-button-text" label='Alterar' />
                            </div>
                            <div className="col-12">
                                <label htmlFor="senhaalterada" className="mb-2 md:col-1 md:mb-0">Sua senha foi alterada pela última vez há XXXX.</label>
                            </div>
                        </div>
                    </Fieldset> */}

                    <Fieldset legend="Gerenciar Conta" className='mb-2'>
                        <div className="grid">
                            <div className="col-7 md:col-7">
                                <h3 className='mb-0'>Bloquear Conta</h3>
                                {/* <p>Ao bloquear sua conta, você será deslogado do HEUP e seu perfil ficará inacessível para os outros usuários.</p> */}
                            </div>
                            <div className="col-3 md:col-5 mt-0 md:mt-0 text-right">
                                <Button icon="pi pi-stop-circle" className="p-button-text" label='Bloquear' onClick={(e) => setDisplayContato(true)} />
                            </div>
                            <div className="col-12">
                                <label htmlFor="bloqueio" className="mb-0 md:col-1 md:mb-0">Ao bloquear sua conta, você será deslogado do HEUP e seu perfil ficará inacessível para os outros usuários.</label>
                            </div>
                            <div className="col-7 md:col-7">
                                <h3 className='mb-0'>Excluir Conta</h3>
                                {/* <p>Ao excluir sua conta, seus dados ficarão inacessíveis definitivamente para outros usuários.</p> */}
                            </div>
                            <div className="col-3 md:col-5 mt-0 md:mt-0 text-right">
                                <Button icon="pi pi-ban" className="p-button-text" label='Excluir' onClick={(e) => setDisplayContato(true)} />
                            </div>
                            <div className="col-12">
                                <label htmlFor="exclusao" className="mb-2 md:col-1 md:mb-0">Ao excluir sua conta, seus dados ficarão inacessíveis definitivamente para outros usuários.</label>
                            </div>

                            <Dialog header="Ação" visible={displayContato} onHide={() => setDisplayContato(false)} style={{ width: '350px' }} modal footer={contatoDialogFooter}>
                                <div className="flex align-items-center justify-content-center">
                                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                    <span>Entre em contato com nosso suporte: (66) 3424-2666.</span>
                                </div>
                            </Dialog>
                        </div>
                    </Fieldset>


                    {/* <div className="col-12">
                        <Toast ref={toast} />
                        <Toolbar className="mb-2 " left={leftToolbarTemplate}></Toolbar>
                    </div> */}

                </div >
            </div >
        </div >
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(PerfilContaUsuarioConfiguracoes, comparisonFn);
