import React, { useState, useEffect } from 'react';

import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { AutoComplete } from 'primereact/autocomplete';
import { classNames } from 'primereact/utils';
import api from '../service/api';

const Dashboard = () => {

    const [city, setCity] = useState(null);
    const [autoFilteredValueUsuario, setAutoFilteredValueUsuario] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [usuario, setUsuario] = useState(null);
    const [listaUsuarios, setListaUsuarios] = useState([]);
    // const [listaAvisos, setListaAvisos] = useState([{ id: 1, data: "03/03/23", mensagem: "Mensagem1", departamento: "Comercial", nivel: 2 }, { id: 1, data: "02/03/23", mensagem: "Mensagem2", departamento: "Desenvolvimento", nivel: 1 }]);
    const [listaAvisos, setListaAvisos] = useState([]);

    let cities = [
        { label: 'Comercial', value: { id: 1, name: 'New York', code: 'NY' } },
        { label: 'Suporte Técncio Hardware', value: { id: 2, name: 'Rome', code: 'RM' } },
        { label: 'Suporte Técnico Software', value: { id: 3, name: 'London', code: 'LDN' } },
    ];

    useEffect(() => {
        const cliente = JSON.parse(localStorage.getItem("cliente"));
        const buscarUsuarios = () => {
            api
                .get("/clientes/" + cliente.id + "/usuarios")
                .then(response => {
                    if (response.data) {
                        setListaUsuarios(response.data);
                    } else {
                        setListaUsuarios([]);
                    }
                })
                .catch(() => {
                    setListaUsuarios([]);
                })
        }

        const buscarAvisos = () => {
            api
                .get("/avisos/" + cliente.id)
                .then(response => {
                    if (response.data) {
                        setListaAvisos(response.data);
                    } else {
                        setListaAvisos([]);
                    }
                })
                .catch(() => {
                    setListaAvisos([]);
                })
        }
        buscarUsuarios();
        buscarAvisos();
    }, []);


    const onCityChange = (e) => {
        setCity(e.value);
    };

    const onInputChange = (e) => {
        const val = (e.target && e.target.value) || '';
        setUsuario(val);
    }

    const procurarUsuario = (e) => {
        setTimeout(() => {
            if (e.query.trim().length >= 4) {
                buscarUsuario(e.query);
            }
        }, 250);
    };

    const buscarUsuario = (nomeUsuario) => {
        api
            .get("/usuarios?nome=" + nomeUsuario)
            .then(response => {
                setAutoFilteredValueUsuario(response.data.filter((em) => {
                    return em.nomeUsuario.toLowerCase();
                }));
            })
            .catch(() => {
                // addMessage("error", Mensagens.ERRO);
            })
    }

    const addUsuario = () => {
        const cliente = JSON.parse(localStorage.getItem("cliente"));
        if (usuario) {
            api
                .post("/clientes/" + cliente.id + "/usuarios", {
                    id: usuario.id,
                })
                .then(response => {
                    setListaUsuarios(response.data);
                })
                .catch(() => {
                    // addMessage("error", Mensagens.ERRO);
                })
        }
    }

    const removeUsuario = () => {
        const cliente = JSON.parse(localStorage.getItem("cliente"));
        if (usuario) {
            api
                .delete("/clientes/" + cliente.id + "/usuarios/" + usuario.id)
                .then(response => {
                    setListaUsuarios(response.data);
                })
                .catch(() => {
                    // addMessage("error", Mensagens.ERRO);
                })
        }
    }

    return (
        <div className="grid dashboard">
            <div className="col-12 md:col-6 lg:col-4 task-list">
                <Panel header="Avisos">
                    <div className="col-12 timeline p-fluid">
                        <div className="grid">

                            {listaAvisos.map((item, index) => (
                                <React.Fragment key={index}>
                                    <div className="col-3">
                                        <span className="event-time">{item.data}</span>
                                        <i className={item.nivel === 1 ? "pi pi-fw pi-exclamation-circle" : "pi pi-fw pi-comment"} style={{ color: '#3984b8' }}></i>
                                    </div>

                                    <div className="col-9">
                                        <span className="event-owner" style={item.nivel === 1 ? { color: '#e175a0' } : { color: '#7e8dcd' }}>{item.departamento}</span>
                                        <span className="event-text">{item.mensagem}</span>
                                        {/* <div className="event-content">
                                            <img alt="Map" src="assets/layout/images/dashboard/map.png" />
                                        </div> */}
                                    </div>
                                </React.Fragment>
                            ))}

                         

                            <div className="col-12">
                                <Button label="Atualizar" icon="pi pi-refresh"
                                    className="rounded-btn raised-btn"></Button>
                            </div>
                        </div>
                    </div>
                </Panel>
            </div>

            <div className="col-12 md:col-6 lg:col-4 p-fluid contact-form">
                <Panel header="Chamado">
                    <div className="grid">
                        <div className="col-12">
                            <Dropdown value={city} options={cities} placeholder="Departamento" onChange={onCityChange} />
                        </div>
                        {/* <div className="col-12">
                            <InputText type="text" placeholder="Nome" />
                        </div> */}
                        {/* <div className="col-12">
                            <InputText type="text" placeholder="Age" />
                        </div> */}
                        {/* <div className="col-12">
                            <InputText type="text" placeholder="Email" />
                        </div> */}
                        <div className="col-12">
                            <InputTextarea placeholder="Mensagem" />
                        </div>
                        <div className="col-12">
                            <Button type="button" label="Enviar" icon="pi pi-check" />
                        </div>
                    </div>
                </Panel>
            </div>

            <div className="col-12 lg:col-4 contacts">
                <Panel header="Time" style={{ minHeight: '360px' }}>

                    <ul>
                        {listaUsuarios.map((item, index) => (
                            <li key={index} className="clearfix">
                                <img alt="User" src="assets/demo/images/galleria/semfoto.jpg" width="45" />
                                <div className="contact-info">
                                    <span className="name">{item.nomeUsuario}</span>
                                    <span className="location">{item.email}</span>
                                </div>
                                <div className="contact-actions">
                                    <Button icon="pi pi-comment"></Button>
                                    {/* <Button icon="pi pi-reply" className="p-button-success"></Button> */}
                                </div>
                            </li>
                        ))}
                        <li className="clearfix">
                            <div className="p-fluid formgrid grid mt-2">
                                <div className="col-12">
                                    <div className="p-inputgroup">
                                        <AutoComplete placeholder="Nome de usuário" id="usuario" value={usuario} onChange={(e) => onInputChange(e)} suggestions={autoFilteredValueUsuario} completeMethod={procurarUsuario} field="nomeUsuario" required className={classNames({ 'p-invalid': submitted && !usuario })} />
                                        {submitted && !usuario && <small className="p-invalid">Obrigatório</small>}
                                        <Button icon="pi pi-plus" className="p-button-success" onClick={addUsuario} />
                                        <Button icon="pi pi-minus" className="p-button-danger" onClick={removeUsuario} />
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>

                </Panel>
            </div>
        </div >
    )

}

const comparisonFn = function (prevProps, nextProps) {
    return (prevProps.location.pathname === nextProps.location.pathname) && (prevProps.colorMode === nextProps.colorMode);
};

export default React.memo(Dashboard, comparisonFn);
