import React, { useState, useRef, useEffect } from 'react';
import api from "../service/api";
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Messages } from 'primereact/messages';
import Mensagens from '../globais/Mensagens';
import { AutoComplete } from 'primereact/autocomplete';
import { classNames } from 'primereact/utils';
import BTGravar from '../components/BTGravar';
import { addLocale, locale } from 'primereact/api';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import Formatacao from '../utilities/Formatacao';
import { SelectButton } from 'primereact/selectbutton';
import ConsultaFixaGenerica from '../components/consultas/ConsultaFixaGenerica';

const BHIMovimentoHoras = () => {

    let emptyModel = {
        id: null,
        empresa: null,
        colaborador: null,
        bancoHoras: null,
        dataS: '',
        valorS: '00:00',
        breveRelato: '',
        tipo: { tipo: 'DÉBITO', id: 1 },
    };

    addLocale('br', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
        dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
        dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'SA'],
        monthNames: ['janeiro', 'fevereiro', 'março', 'abril', '´maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
        monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
        today: 'Hoje',
        clear: 'Limpar'
    });

    locale("br");

    let hoje = new Date();
    let formatacao = new Formatacao();

    const toast = useRef(null);
    const [model, setModel] = useState(emptyModel);
    const [submitted, setSubmitted] = useState(false);
    const message = useRef();
    const [autoFilteredValueEmpresa, setAutoFilteredValueEmpresa] = useState([]);
    const [autoValueEmpresa, setAutoValueEmpresa] = useState(null);
    const [autoFilteredValueColaborador, setAutoFilteredValueColaborador] = useState([]);
    const [autoValueColaborador, setAutoValueColaborador] = useState(null);
    const [autoFilteredValueBancoHoras, setAutoFilteredValueBancoHoras] = useState([]);
    const [autoValueBancoHoras, setAutoValueBancoHoras] = useState(null);
    const [data, setData] = useState(hoje);
    const [lista, setLista] = useState(null);

    const colunas = [
        { field: 'dataS', header: 'Data', headerStyle: { maxWidth: '5rem' } },
        { field: 'valorS', header: 'Valor', headerStyle: { maxWidth: '5rem' } },
        { field: 'tipo.tipo', header: 'Tipo', headerStyle: { maxWidth: '5rem' } },
        { field: 'breveRelato', header: 'Breve Relato', headerStyle: { maxWidth: '5rem' } },
    ];

    const tipoRegistroValores = [
        { tipo: 'DÉBITO', id: 1 },
        { tipo: 'CRÉDITO', id: 2 },
    ];

    useEffect(() => {
        const buscaEmpresas = () => {
            api
                .get("/empresas")
                .then(response => {
                    setAutoValueEmpresa(response.data);
                    model.empresa = response.data[0];
                    buscarBancoHoras(response.data[0]);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }
        buscaEmpresas();
    }, []);

    const selecao = (e) => {
        // setModel(e.value);
    }

    const buscaColaboradores = (empresa, bancoHoras) => {
        if (empresa !== null) {
            api.get("/colaboradores?empresa=" + empresa.id + "&bancoHoras=" + bancoHoras.id)
                .then((response) => {
                    setAutoValueColaborador(response.data);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                });
        }
    };

    const buscarMovimento = (colaborador, empresa, bancoHoras) => {
        if (colaborador !== null && empresa !== null && bancoHoras !== null) {
            api
                .get("/bancoHoras/movimento?colaborador= " + colaborador.id + "&empresa=" + empresa.id + "&bancoHoras=" + bancoHoras.id)
                .then(response => {
                    setLista(response.data);
                    // if (response.data === true) {
                    //     addMessage("warn", Mensagens.AVISO_COLABORADOR_INICIADO);
                    // }
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }
    }

    const buscarBancoHoras = (empresa) => {
        if (empresa !== null) {
            api.get("/bancoHoras/periodoCompetencia?empresa=" + empresa.id)
                .then((response) => {
                    setAutoValueBancoHoras(response.data);
                    model.bancoHoras = response.data[0];
                    buscaColaboradores(empresa, response.data[0]);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                });
        }
    };

    const onInputChange = (e, descricao) => {

        let val = (e.target && e.target.value) || '';

        if (descricao === 'valorS') {
            val = formatacao.formatarTempo(e.target.value);
        }

        let _model = { ...model };
        _model[`${descricao}`] = val;
        setModel(_model);

        if (descricao === "empresa" || descricao === "bancoHoras") {
            buscaColaboradores(_model.empresa, _model.bancoHoras);
        }

        if (descricao === 'colaborador') {
            buscarMovimento(_model.colaborador, _model.empresa, _model.bancoHoras);
        }
    }

    const procurarEmpresas = (event) => {
        setTimeout(() => {
            try {
                if (!event.query.trim().length) {
                    setAutoFilteredValueEmpresa([...autoValueEmpresa]);
                }
                else {
                    setAutoFilteredValueEmpresa(autoValueEmpresa.filter((em) => {
                        return em.fantasia.toLowerCase().startsWith(event.query.toLowerCase());

                    }));
                }
            } catch (e) {
            }

        }, 250);
    };

    const procurarColaboradores = (event) => {
        setTimeout(() => {
            try {
                if (!event.query.trim().length) {
                    setAutoFilteredValueColaborador([...autoValueColaborador]);
                }
                else {
                    setAutoFilteredValueColaborador(autoValueColaborador.filter((em) => {
                        return em.nome.toLowerCase().startsWith(event.query.toLowerCase());
                    }));
                }
            } catch (e) {
            }

        }, 250);
    };

    const procurarBancoHoras = (event) => {
        setTimeout(() => {
            try {
                if (!event.query.trim().length) {
                    setAutoFilteredValueBancoHoras([...autoValueBancoHoras]);
                }
                else {
                    setAutoFilteredValueBancoHoras(autoValueBancoHoras.filter((em) => {
                        return em.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                    }));
                }
            } catch (e) {
            }

        }, 250);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <BTGravar setSubmitted={setSubmitted} cadastrar={cadastrar} />
                    <Messages ref={message} />
                </div>
            </React.Fragment>
        )
    }

    const cadastrar = () => {
        if(!model.colaborador){
            addMessage("error", Mensagens.SELECIONE_COLABORADOR);
            return;
        }

        if(model.valorS.trim().length < 5){
            addMessage("error", Mensagens.HORARIO_VALIDO);
            return;
        }

        if (model.colaborador && model.empresa && model.bancoHoras && data
            && model.valorS.trim() && model.valorS.trim().length >= 5) {
            api
                .post("/bancoHoras/movimento", {
                    empresa: model.empresa,
                    colaborador: model.colaborador,
                    bancoHoras: model.bancoHoras,
                    dataS: data.getDate() + "/" + (data.getMonth() + 1) + "/" + data.getFullYear(),
                    valorS: model.valorS,
                    breveRelato: model.breveRelato,
                    tipo: model.tipo,
                })
                .then(response => {
                    if (response.data.message) {
                        addMessage("success", response.data.message);
                    } else {
                        addMessage("success", Mensagens.OPERACAO_SUCESSO);
                    }
                    buscarMovimento(model.colaborador, model.empresa, model.bancoHoras);
                })
                .catch(error => {
                    if (error.response.data.message) {
                        addMessage("error", error.response.data.message);
                    } else {
                        addMessage("error", Mensagens.ERRO);
                    }
                })
        }
    }

    const addMessage = (sv, cn) => {
        message.current.show({ severity: sv, content: cn, life: 10000 });
    };

    const selectChange = (e, descricao) => {
        const val = (e.value) || '';
        let _model = { ...model };
        _model[`${descricao}`] = val;
        setModel(_model);
    }

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="empresa" className="col-12 md:col-1 md:mb-0">Empresa*</label>
                        <div className="col-12 md:col-11">
                            <AutoComplete placeholder="Procure" id="empresa" dropdown value={model.empresa} onChange={(e) => onInputChange(e, 'empresa')} suggestions={autoFilteredValueEmpresa} completeMethod={procurarEmpresas} field="fantasia" required className={classNames({ 'p-invalid': submitted && !model.empresa })} />
                            {submitted && !model.empresa && <small className="p-invalid">Empresa Obrigatória</small>}
                        </div>
                    </div>

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="bancohoras" className="col-12 md:col-1 md:mb-0">Banco de Horas</label>
                        <div className="col-12 md:col-11">
                            <AutoComplete placeholder="Procure" id="bancohoras" dropdown value={model.bancoHoras} onChange={(e) => onInputChange(e, 'bancoHoras')} suggestions={autoFilteredValueBancoHoras} completeMethod={procurarBancoHoras} field="descricao" />
                        </div>
                    </div>

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="colaborador" className="col-12 md:col-1 md:mb-0">Colaborador*</label>
                        <div className="col-12 md:col-11">
                            <AutoComplete placeholder="Procure" id="colaborador" dropdown value={model.colaborador} onChange={(e) => onInputChange(e, 'colaborador')} suggestions={autoFilteredValueColaborador} completeMethod={procurarColaboradores} field="nome" />
                        </div>
                    </div>

                    <div className="p-fluid formgrid grid">
                        <label htmlFor="tipoRegistro" className="col-12 md:col-1 md:mt-0">Tipo de Registro</label>
                        <div className="field col-12 md:col-4 mb-2">
                            <SelectButton value={model.tipo} onChange={(e) => selectChange(e, 'tipo')} options={tipoRegistroValores} optionLabel="tipo" />
                        </div>
                    </div>

                    <div className="p-fluid formgrid grid">
                        <label htmlFor="datainicial" className="col-12 md:col-1 md:mt-2">Data*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <Calendar dateFormat="dd/mm/yy" value={data} onChange={(e) => setData(e.value)} monthNavigator yearNavigator yearRange="2023:2050" showIcon required className={classNames({ 'p-invalid': submitted && !data })}></Calendar>
                            {submitted && !data && <small className="p-invalid">Data Obrigatória</small>}
                        </div>


                    </div>

                    <div className="p-fluid formgrid grid">
                        <label htmlFor="valor" className="col-12 md:col-1 md:mt-2">Valor*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputText id="valor" value={model.valorS} onChange={(e) => onInputChange(e, 'valorS')} required className={classNames({ 'p-invalid': submitted && !model.valorS })} />
                            {submitted && !model.valorS && <small className="p-invalid">Valor Obrigatório</small>}
                        </div>
                    </div>

                    <div className="field grid p-fluid">
                        <label htmlFor="relato" className="col-12 mb-2 md:col-1 md:mb-0">Breve Relato</label>
                        <div className="col-12 md:col-11">
                            <InputText id="relato" maxLength={100} placeholder="Máximo 100 caracteres" value={model.breveRelato} onChange={(e) => onInputChange(e, 'breveRelato')} />
                        </div>
                    </div>

                    <div className="col-12">
                        <Toast ref={toast} />
                        <Toolbar className="mb-2 " left={leftToolbarTemplate}></Toolbar>
                    </div>

                    <ConsultaFixaGenerica
                        lista={lista}
                        model={model}
                        selecao={selecao}
                        camposFiltroGlobal={['dataS', 'valorS', 'tipo.tipo', 'breveRelato']}
                        colunas={colunas}
                    />

                </div >
            </div >
        </div >
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(BHIMovimentoHoras, comparisonFn);
