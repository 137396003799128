import React, { useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import { useHistory } from 'react-router-dom';
import { classNames } from 'primereact/utils';
import { Messages } from 'primereact/messages';
import apiAutenticacao from '../service/apiAutenticacao';
import { Password } from 'primereact/password';
import { Divider } from 'primereact/divider';
import Mensagens from '../globais/Mensagens';
import validator from 'email-validator';
import Formatacao from '../utilities/Formatacao';
import { cpf } from 'cpf-cnpj-validator';

export const RegistroUsuario = () => {

	let emptyModel = {
		id: null,
		nomeUsuario: '',
		senha: '',
		senhaRepetida: '',
		email: '',
		telefone: '',
		cpf: '',
		nome: '',
		aceite: false,
	};

	const history = useHistory();
	const [model, setModel] = useState(emptyModel);
	const [submitted, setSubmitted] = useState(false);
	const [emailValido, setEmailValido] = useState(false);
	const [usuarioValido, setUsuarioValido] = useState(false);
	const [senhaValida, setSenhaValida] = useState(false);
	const [senhaRepetidaValida, setSenhaRepetidaValida] = useState(false);
	const [cpfValido, setCpfValido] = useState(false);
	const [presencaMinuscula, setPresencaMinuscula] = useState(false);
	const [presencaMaiuscula, setPresencaMaiuscula] = useState(false);
	const [presencaNumero, setPresencaNumero] = useState(false);
	const [presencaMinimoCaracteres, setPresencaMinimoCaracteres] = useState(false);
	const [senhaRepetidaDigitada, setSenhaRepetidaDigitada] = useState(false);
	const message = useRef();

	let formatacao = new Formatacao();

	const footer = (
		<React.Fragment>
			<Divider />
			<p className="mt-2">Obrigatório</p>
			<ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
				<li style={{ color: presencaMinuscula ? 'green' : 'red' }}>Pelo menos uma minúscula</li>
				<li style={{ color: presencaMaiuscula ? 'green' : 'red' }}>Pelo menos uma maiúscula</li>
				<li style={{ color: presencaNumero ? 'green' : 'red' }}>Pelo menos um número</li>
				<li style={{ color: presencaMinimoCaracteres ? 'green' : 'red' }}>Mínimo de 8 caracteres</li>
			</ul>
		</React.Fragment>
	);

	const goLogin = () => {
		history.push('/login');
	}

	const validateEmail = (email) => {
		setEmailValido(validator.validate(email));
	}

	const validateCpf = (num) => {
		setCpfValido(cpf.isValid(num));
	}

	const validateUsuario = (usuario) => {
		let valido = false;

		const temEspaco = usuario.includes(' ');

		const regex = /^[a-zA-Z0-9]+$/;
		const letrasNumeros = regex.test(usuario);

		let maiuscula = /^(?=.*[A-Z]).*$/;
		const temMaiscula = maiuscula.test(usuario);

		if (temEspaco === false & usuario.length >= 4 & letrasNumeros & temMaiscula === false) {
			valido = true;
		}

		setUsuarioValido(valido);
	}

	const validateSenha = (senha) => {
		setPresencaMinuscula(false);
		setPresencaMaiuscula(false);
		setPresencaNumero(false);
		setPresencaMinimoCaracteres(false);

		let minuscula = /^(?=.*[a-z]).*$/;
		let maiuscula = /^(?=.*[A-Z]).*$/;
		let numero = /^(?=.*\d).*$/;
		let minimoCaracteres = senha.trim().length >= 8;

		const senhaRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/;

		if (minuscula.test(senha)) {
			setPresencaMinuscula(true)
		}
		if (maiuscula.test(senha)) {
			setPresencaMaiuscula(true);
		}
		if (numero.test(senha)) {
			setPresencaNumero(true);
		}
		if (minimoCaracteres) {
			setPresencaMinimoCaracteres(true);
		}

		const isSenhaValida = senha.trim().length >= 8 && senhaRegex.test(senha);

		setSenhaValida(isSenhaValida);
	}

	const validateRepetirSenha = (senhaAtual, senhaRepetidaAtual) => {
		setSenhaRepetidaValida(false);
		if (senhaAtual === senhaRepetidaAtual) {
			setSenhaRepetidaValida(true);
		}
	}

	const addMessage = (sv, cn) => {
		message.current.show({ severity: sv, content: cn });
	};

	const cadastrar = () => {
		setSubmitted(true);
		if (model.email.trim() && model.nomeUsuario.trim() && model.senha.trim() && emailValido
			&& usuarioValido && senhaValida && senhaRepetidaValida) {
			apiAutenticacao
				.post("/registrar", {
					id: model.id,
					email: model.email,
					nomeUsuario: model.nomeUsuario,
					senha: model.senha,
					cpf: model.cpf,
					nome: model.nome,
					telefone: model.telefone
				})
				.then(response => {
					addMessage("success", response.data.message);
				})
				.catch(erro => {
					if (erro.response) {
						addMessage("error", erro.response.data.message);
					} else {
						addMessage("error", Mensagens.ERRO);
					}
				})
			setSubmitted(false)
		}
	}

	const onInputChange = (e, descricao) => {
		const val = (e.target && e.target.value) || '';
		let _model = { ...model };
		_model[`${descricao}`] = val;
		setModel(_model);

		if (descricao === 'senha') {
			validateSenha(_model.senha);
			validateRepetirSenha(_model.senha, _model.senhaRepetida);
		}
		if (descricao === 'senhaRepetida') {
			setSenhaRepetidaDigitada(true);
			validateRepetirSenha(_model.senha, _model.senhaRepetida);
		}
		if(descricao === 'nomeUsuario'){
			validateUsuario(_model.nomeUsuario);
		}
	}

	return (
		<div className="login-body">
			<div className="body-container">
				<div className="grid">
					<div className="col-12 lg:col-6 left-side">
						<img src="assets/layout/images/logo-white-he.png" alt="apollo-layout" className="logo" />
						<h1>Comece agora mesmo!</h1>
						<p>
							Realize o tratamento de Ponto de forma rápida e fácil.
						</p>
					</div>
					<div className="col-12 lg:col-6 right-side">
						<div className="login-wrapper">
							<div className="login-container">
								<span className="title">Registre-se</span>

								<div className="grid p-fluid">

									<div className="col-12">
										<div className="p-field">
											<InputText id="nome" placeholder="Nome completo*" onChange={(e) => onInputChange(e, 'nome')} required className={classNames({ 'p-invalid': submitted && !model.nome })} />
											{submitted && !model.nome && <small className="p-invalid">Nome obrigatório.</small>}
										</div>
									</div>

									<div className="col-12">
										<div className="p-field">
											<span className="p-float-label p-input-icon-right">
												<i className="pi pi-envelope" />
												<InputText placeholder="Email*" id="email" onBlur={(e) => validateEmail(e.target.value)} onChange={(e) => onInputChange(e, 'email')} required className={classNames({ 'p-invalid': submitted && !emailValido })} />
												{/* {submitted && !model.email && <small className="p-invalid">Email obrigatório</small>} */}
												{submitted && !emailValido && <small className="p-invalid">Email inválido: exemplo nome@exemplo.com</small>}
											</span>
										</div>
									</div>

									<div className="col-6">
										<div className="p-field">
											<InputText id="cpf" placeholder="CPF*" value={cpf.format(model.cpf)} onBlur={(e) => validateCpf(e.target.value)} onChange={(e) => onInputChange(e, 'cpf')} required className={classNames({ 'p-invalid': submitted && !cpfValido })} />
											{submitted && !cpfValido && <small className="p-invalid">CPF inválido.</small>}
										</div>
									</div>

									<div className="col-6">
										<div className="p-field">
											<span className="p-float-label p-input-icon-right">
												<i className="pi pi-mobile" />
												<InputText id="telefone" placeholder="Telefone*" maxLength="15" value={formatacao.formatarTelefone(model.telefone)} onChange={(e) => onInputChange(e, 'telefone')} required className={classNames({ 'p-invalid': submitted && !model.telefone })} />
												{submitted && !model.telefone && <small className="p-invalid">Telefone	 obrigatório.</small>}
											</span>
										</div>
									</div>

									<div className="col-12">
										<div className="p-field">
											<span className="p-float-label p-input-icon-right">
												<i className="pi pi-user" />
												<InputText id="nomeUsuario" placeholder="Nome de usuário*" onBlur={(e) => validateUsuario(e.target.value)} onChange={(e) => onInputChange(e, 'nomeUsuario')} required className={classNames({ 'p-invalid': submitted && !usuarioValido })} />
												{submitted && !usuarioValido && <small className="p-invalid">Minímo 4 caracteres, somente letras e números, todas minúsculas e sem espaços.</small>}
											</span>
										</div>
									</div>

									<div className="col-6">
										<Password id="senha" placeholder="Senha*" toggleMask weakLabel="Fraca" mediumLabel='Média' strongLabel='Forte' promptLabel='Digite a senha' footer={footer} onBlur={(e) => validateSenha(e.target.value)} onChange={(e) => onInputChange(e, 'senha')} required className={classNames({ 'p-invalid': submitted && !senhaValida })} />
										{submitted && !senhaValida && <small className="p-invalid">Senha inválida</small>}
									</div>

									<div className="col-6">
										<Password id="repitasenha" placeholder="Repetir senha*" toggleMask feedback={false} onChange={(e) => onInputChange(e, 'senhaRepetida')} required className={classNames({ 'p-invalid': senhaRepetidaDigitada && !senhaRepetidaValida })} />
										{submitted && !senhaRepetidaValida && <small className="p-invalid">Senha diferente</small>}
									</div>

									<div className="col-6">
										<Button label="Criar" icon="pi pi-check" onClick={cadastrar} />
									</div>
									<div className="col-6 password-container">
										<button type="text" className="p-link" onClick={goLogin}>Já tenho cadastro!</button>
									</div>
									<div className="col-12">
										<Messages ref={message} />
									</div>


								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
